<template>
  <div class="qk-image">
    <img :src="imageSrc" alt="" crossOrigin="anonymous" />
  </div>
</template>

<script>
export default {
  name: "QkImage",
  props: {
    imageSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com//webactive/image/study-bg.jpg",
    },
  },
};
</script>

<style lang="scss" scoped>
.qk-image {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
}
</style>
