<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>
</template>

<script>
export default { name: "App" };
</script>

<style lang="scss">
html,
body,
div {
  padding: 0;
  margin: 0;
}

.page {
  width: 1920px;
  height: 1080px;
  // background-image: url("@/assets/images/background.jpg");
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  box-sizing: border-box;
  overflow: hidden;
}

.m-l-r-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-80 {
  margin-bottom: 80px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-80 {
  margin-right: 80px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-r-40 {
  padding-right: 40px;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-around-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.t-i-40 {
  text-indent: 40px;
}

.f-w-b {
  font-weight: bold;
}

.f-s-26 {
  font-size: 26px;
}

.f-s-30 {
  font-size: 30px;
}

.f-s-36 {
  font-size: 36px;
}

.f-s-40 {
  font-size: 40px;
}

.icon-prev {
  transform: rotate(180deg);
}

.submit-btn {
  min-width: 210px;
  height: 75px;
  padding: 0 30px;
  margin: 0 auto;
  border-radius: 15px;
  text-align: center;
  line-height: 75px;
  color: #fff;
  font-size: 34px;
  letter-spacing: 4px;
}

.c-red {
  color: rgb(232, 52, 40);
}

button {
  position: relative;
  display: inline-block;
  margin: auto;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);

  &::after {
    border: none;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0);
  }
}

.empty {
  padding: 40px 0;
  font-size: 30px;
  text-align: center;
  color: #999;
}

.page-sub-title {
  font-size: 28px;
  font-weight: 600;
  color: #122987;
  margin: 0 49px 20px;
}

.line-top-right {
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  bottom: 20%;
  z-index: 10;
  background: linear-gradient(
    to top right,
    transparent 0%,
    transparent calc(50% - 1px),
    #333 50%,
    transparent calc(50% + 1px),
    transparent 100%
  );
  pointer-events: none;
}

.t-d-l-t {
  text-decoration: line-through;
}
</style>
