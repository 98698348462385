var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qk-aixili-fanka"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"header-left",attrs:{"src":_vm.aixiliTitleImgSrc,"width":_vm.aixiliTitleImgWidth,"height":_vm.aixiliTitleImgHeight}}),_c('img',{staticClass:"header-right",attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"}})]),_c('div',{staticClass:"content",style:({
      width: `${_vm.aixiliContentWidth}px`,
      height: `${_vm.aixiliContentHeight}px`,
      backgroundSize: `${_vm.aixiliContentBackgroundSize}`,
      backgroundRepeat: `${_vm.aixiliContentBackgroundRepeat}`,
      backgroundImage: `url('${_vm.aixiliContentBackgroundImageSrc}')`,
    })},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"left-top"},[_c('div',{staticClass:"top-title"},[_c('img',{attrs:{"src":_vm.aixiliDataContentFanka.subTitleImgSrc,"width":_vm.aixiliDataContentFanka.subTitleImgWidth,"height":_vm.aixiliDataContentFanka.subTitleImgHeight}})])]),_vm._m(0)]),_c('div',{staticClass:"content-center"},[_c('div',{staticClass:"center-top"},[_c('div',{staticClass:"top-left"},[_c('img',{staticClass:"top-title",attrs:{"src":_vm.aixiliDataContentFanka.mainTitleImgSrc,"width":_vm.aixiliDataContentFanka.mainTitleImgWidth,"height":_vm.aixiliDataContentFanka.mainTitleHeight}})]),_c('div',{staticClass:"top-right"},[_c('div',{staticStyle:{"margin-right":"10px"},on:{"click":function($event){$event.stopPropagation();return _vm.addValue.apply(null, arguments)}}},[_c('img',{attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-74.jpg"}})]),_c('div',{staticStyle:{"margin-right":"20px"},on:{"click":function($event){$event.stopPropagation();return _vm.decValue.apply(null, arguments)}}},[_c('img',{attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-75.jpg"}})]),_c('div',{staticClass:"right-button",on:{"click":function($event){$event.stopPropagation();return _vm.saveValue.apply(null, arguments)}}},[_c('img',{attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-76.jpg"}})])])]),_c('div',{staticClass:"center-content"},_vm._l((_vm.dataContent),function(item,index){return _c('div',{key:index,staticClass:"content-flex"},[(item.type === 'empty')?_c('img',{attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-89.jpg"}}):_c('div',{staticClass:"flex-input"},[_c('el-input',{staticStyle:{"width":"90%"}})],1)])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-middle"},[_c('img',{attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-77.jpg"}})])
}]

export { render, staticRenderFns }