<!-- 视频组件 -->
<template>
  <div class="qk-video">
    <video
      :src="videoSrc"
      :poster="videoPoster"
      :preload="preload"
      :playsinline="playsinline"
      :controls="controls"
      :controlslist="controlslist"
      :title="title"
    ></video>
  </div>
</template>

<script>
export default {
  name: "QkVideo", // 这个名字很重要，它就是未来的标签名<qk-video></qk-video>
  props: {
    title: {
      type: String,
      default: null,
    },
    preload: {
      type: String,
      default: null, // none/metadata/auto
    },
    playsinline: {
      type: Boolean,
      default: true,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    controlslist: {
      type: String,
      default: null,
    },
    videoSrc: {
      type: String,
      default: "https://static-oss.abiefamily.com//webactive/video/hezou.mp4",
    },
    videoPoster: {
      type: String,
      default: "https://static-oss.abiefamily.com//webactive/image/ai-bg.jpg",
    },
  },
};
</script>

<style lang="scss" scoped>
.qk-video {
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}
.poster {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
