<!-- 空白占位组件 -->
<template>
  <div class="qk-blank">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "QkBlank", // 这个名字很重要，它就是未来的标签名 <qk-blank></qk-blank>
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
