<template>
  <div class="qk-aixili-fanka">
    <div class="header">
      <img
        :src="aixiliTitleImgSrc"
        :width="`${aixiliTitleImgWidth}px`"
        :height="`${aixiliTitleImgHeight}px`"
        class="header-left"
      />
      <img
        src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"
        class="header-right"
      />
    </div>
    <div
      class="content"
      :style="{
        width: `${aixiliContentWidth}px`,
        height: `${aixiliContentHeight}px`,
        backgroundSize: `${aixiliContentBackgroundSize}`,
        backgroundRepeat: `${aixiliContentBackgroundRepeat}`,
        backgroundImage: `url('${aixiliContentBackgroundImageSrc}')`,
      }"
    >
      <div class="content-left">
        <div class="left-top">
          <div
            v-for="(item, index) in aixiliDataContentFankaa"
            :key="index"
            @click.stop="() => selectButtonActive(index)"
          >
            <img
              :src="
                index === activeButton
                  ? item.activeButtonImageSrc
                  : item.inActiveButtonImageSrc
              "
              class="top-item"
            />
          </div>
        </div>
        <div class="left-middle">
          <img
            :src="aixiliDataContentFankaa[activeButton].leftImageSrc"
            :width="`${aixiliDataContentFankaa[activeButton].leftImageWidth}px`"
            :height="`${aixiliDataContentFankaa[activeButton].leftImageHeight}px`"
          />
        </div>
        <div class="left-bottom">
          <img
            src="https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411664743.jpg"
            class="bottom-image"
          />
        </div>
      </div>
      <div class="content-rigth">
        <img
          :src="aixiliDataContentFankaa[activeButton].rightImageSrc"
          :width="`${aixiliDataContentFankaa[activeButton].rightImageWidth}px`"
          :height="`${aixiliDataContentFankaa[activeButton].rightImageHeight}px`"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QkAixiliFanka2",
  props: {
    aixiliTitleImgSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-69.jpg",
    },
    aixiliTitleImgWidth: {
      type: Number,
      default: 92,
    },
    aixiliTitleImgHeight: {
      type: Number,
      default: 41,
    },
    aixiliContentBackgroundImageSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-68.jpg",
    },
    aixiliContentWidth: {
      type: Number,
      default: 1400,
    },
    aixiliContentHeight: {
      type: Number,
      default: 636,
    },
    aixiliContentBackgroundSize: {
      type: String,
      default: "cover",
    },
    aixiliContentBackgroundRepeat: {
      type: String,
      default: "no-repeat",
    },
    aixiliDataContentFankaa: {
      type: Array,
      default: () => [
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411475438.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411475438.jpg",
          leftImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411628833.jpg",
          leftImageWidth: 535,
          leftImageHeight: 112,
          rightImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411605371.jpg",
          rightImageWidth: 410,
          rightImageHeight: 470,
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411519622.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411519622.jpg",
          leftImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411628833.jpg",
          leftImageWidth: 535,
          leftImageHeight: 112,
          rightImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411605371.jpg",
          rightImageWidth: 410,
          rightImageHeight: 470,
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411561105.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411561105.jpg",
          leftImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411628833.jpg",
          leftImageWidth: 535,
          leftImageHeight: 112,
          rightImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411605371.jpg",
          rightImageWidth: 410,
          rightImageHeight: 470,
        },
      ],
    },
  },
  data() {
    return {
      activeButton: 0,
    };
  },
  methods: {
    selectButtonActive(index) {
      this.activeButton = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.qk-aixili-fanka {
  width: 100%;
  height: 100%;
  background: #ffffff;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .header-left {
      margin-left: 92px;
      margin-top: 57px;
    }
    .header-right {
      width: 198px;
      height: 73px;
      margin-top: 34px;
      margin-right: 155px;
    }
  }
  .content {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .content-left {
      width: 515px;
      .left-top {
        margin-top: 75px;
        margin-left: 160px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .top-item {
          width: 47px;
          height: 47px;
          margin-right: 25px;
        }
      }
      .left-middle {
        margin-top: 42px;
        margin-left: 67px;
      }
      .left-bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 100px;
        .bottom-image {
          width: 126px;
          height: 92px;
        }
      }
    }
    .content-rigth {
      margin-left: 35px;
      margin-top: 37px;
      .right-content {
        width: 344px;
        height: 394px;
      }
    }
  }
}
</style>
