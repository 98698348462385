<template>
  <div
    class="imgDraw"
    style="
      width: 100%;
      height: 100%;
      position: absolute;
      background: transparent;
      z-index: 3000;
    "
  >
    <a
      v-show="false"
      ref="download"
      href="javascript:void(0);"
      download="picture.png"
    ></a>
    <el-dialog :visible.sync="show" :modal="false">
      <div class="d_body">
        <div ref="board" class="board">
          <img
            src="../../assets/images/close2.png"
            class="close"
            style="
              background: rgba(195, 193, 193, 0.5);
              right: 80px;
              border-radius: 50%;
            "
            @click="closeCanvas"
          />
          <canvas
            id="ctx_front"
            ref="ctx_front"
            :style="'cursor:' + cursor"
          ></canvas>
          <canvas
            id="ctx_base"
            ref="ctx_base"
            :style="'cursor:' + cursor"
          ></canvas>
          <canvas
            id="ctx_back"
            ref="ctx_back"
            :style="'cursor:' + cursor"
          ></canvas>
          <input
            id="text"
            v-model="text"
            autofocus
            autocomplete="off"
            :style="
              'font-size:' + (slide * 10 + 14) + 'px;color:' + defaultColor
            "
            name="text"
            @blur="handleTextBlur"
            @keyup.enter="handleTextBlur"
          />
        </div>
        <div :class="['tools', 'settings', isExpand ? '' : 'noExpand']">
          <div
            v-for="(item, index) in settings"
            :key="item.icon"
            class="tool_item"
          >
            <button v-if="index == 0">
              <i :class="['iconfont', item.icon]" />
              <span :style="'color:' + defaultColor">{{ item.name }}</span>
              <el-color-picker v-model="defaultColor"></el-color-picker>
            </button>
            <div v-else-if="index == 1" class="slide">
              <i :class="['iconfont', item.icon]" />
              <span>{{ item.name }}</span> &emsp;&emsp;
              <el-slider
                ref="slide"
                v-model="slide"
                :min="1"
                :max="10"
                :step="1"
              ></el-slider>
            </div>
            <button v-else @click.stop="item.fun">
              <i :class="['iconfont', item.icon]" />
              <span>{{ item.name }}</span>
            </button>
          </div>
          <div v-for="item in btns" :key="item.icon" class="tool_item">
            <button
              v-if="item.name == '上一步'"
              :disabled="prevDis"
              :style="prevDis ? 'cursor:not-allowed' : ''"
              @click.stop="item.fun"
            >
              <i :class="['iconfont', item.icon]" />
              <span>{{ item.name }}</span>
            </button>
            <button
              v-else-if="item.name == '下一步'"
              :disabled="nextDis"
              :style="nextDis ? 'cursor:not-allowed' : ''"
              @click.stop="item.fun"
            >
              <i :class="['iconfont', item.icon]" />
              <span>{{ item.name }}</span>
            </button>
            <button v-else @click.stop="item.fun">
              <i :class="['iconfont', item.icon]" />
              <span>{{ item.name }}</span>
            </button>
          </div>
          <div class="tool_item go_up">
            <button @click.stop="handleShowOrHide(0)">
              <i class="el-icon-caret-top"></i>
              <span>收起</span>
            </button>
          </div>
          <div v-if="!isExpand" class="pull">
            <span class="line"></span>
            <span
              class="round"
              title="展开"
              @click.stop="handleShowOrHide(1)"
            ></span>
          </div>
        </div>
        <div :class="['tools', 'bars', showTools ? '' : 'hideTools']">
          <div
            v-if="!showTools"
            class="el-icon-s-tools arrow"
            title="展开"
            @click.stop="handleShowTools(1)"
          ></div>
          <div
            v-else
            class="el-icon-arrow-right arrow"
            title="收起"
            @click.stop="handleShowTools(0)"
          ></div>
          <div
            v-for="item in tools"
            :key="item.toolType"
            :class="[
              'tool_item',
              activeTool == item.toolType ? 'activeTool' : '',
            ]"
            @click.stop="handleChangeToolType(item.toolType)"
          >
            <i :class="['iconfont', item.icon]" />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import cursors from "./cursor";
import bg from "./1.png";
import ScreenShort from "js-web-screen-shot";

export default {
  name: "ImgDraw",
  props: {
    toolType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      show: true,
      defaultColor: "#000000",
      cursor: `url('${cursors.pen}'),auto`,
      slide: 1,
      settings: [
        {
          icon: "icon-yanse1",
          name: "颜色",
          fun: "",
        },
        {
          icon: "icon-bi",
          name: "粗细",
          fun: "",
        },
      ],
      activeTool: 1,
      tools: [
        {
          icon: "icon-qianbi",
          name: "画笔",
          toolType: 1,
        },
        {
          icon: "icon-huabigongju-zhixian",
          name: "直线",
          toolType: 2,
        },
        {
          icon: "icon-huabigongju-tuoyuan",
          name: "圆形",
          toolType: 3,
        },
        {
          icon: "icon-huabigongju-juxing",
          name: "矩形",
          toolType: 4,
        },
        {
          icon: "icon-rubber-full",
          name: "橡皮",
          toolType: 5,
        },
        {
          icon: "icon-huabigongju-wenben",
          name: "文字",
          toolType: 6,
        },
      ],
      btns: [
        {
          icon: "icon-shangyibu",
          name: "上一步",
          fun: () => {
            return this.handlePrev();
          },
        },
        {
          icon: "icon-xiayibu",
          name: "下一步",
          fun: () => {
            return this.handleNext();
          },
        },
        {
          icon: "icon-qingkong",
          name: "清除",
          fun: () => {
            return this.handleClearCanvas();
          },
        },
        {
          icon: "icon-baocun1",
          name: "保存",
          fun: () => {
            return this.handleCanvas2Img();
          },
        },
        {
          icon: "icon-jietu",
          name: "截图",
          fun: () => {
            return this.handleCropScreenImg();
          },
        },
      ],
      canvas_front: null,
      canvas_back: null,
      canvas_base: null,
      ctx_base: null,
      ctx_front: null,
      ctx_back: null,
      currentImg: {
        url: bg,
        width: "",
        height: "",
        scale: 1,
        index: 0,
      },
      isExpand: 0,
      showTools: 0,
      canDraw: false,
      text: "",
      canvasStore: [bg],
      prevDis: true,
      nextDis: true,
      baseMap: bg,
      tl: 0,
      tt: 0,
      windowWidth: 0,
      windowHeight: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.showTools = 1;
      this.isExpand = 1;
      this.handleInitCanvas();
      this.handleChangeToolType(this.toolType);
      this.windowWidth = document.documentElement.clientWidth;
      this.windowHeight = document.documentElement.clientHeight;
    });
  },
  methods: {
    /**
     * 显示或隐藏设置栏
     * */
    handleShowOrHide(status) {
      this.isExpand = status;
      this.showTools = status;
    },
    /**
     * 显示或隐藏工具栏
     * */
    handleShowTools(status) {
      this.showTools = status;
      this.isExpand = status;
    },
    closeCanvas() {
      // this.show = false;
      this.$emit("close");
    },
    /**
     * 工具切换
     * */
    handleChangeToolType(type) {
      this.activeTool = type;

      switch (type) {
        case 1:
          this.cursor = `url('${cursors.pen}'),auto`;
          break;
        case 2:
          this.cursor = `crosshair`;
          break;
        case 3:
          this.cursor = `crosshair`;
          break;
        case 4:
          this.cursor = `crosshair`;
          break;
        case 5:
          this.cursor = `url('${cursors.eraser}'),auto`;
          break;
        case 6:
          this.cursor = `url('${cursors.text}'),auto`;
          break;
        default:
          this.cursor = `url('${cursors.pen}'),auto`;
          break;
      }
      this.handleDrawCanvas(type);
    },
    /**
     * 初始化画布
     * */
    handleInitCanvas() {
      this.currentImg = {
        url: bg,
        width: "",
        height: "",
        scale: 1,
        index: 0,
      };
      this.canvasStore = [bg];
      this.prevDis = true;
      this.nextDis = true;

      // 用于绘制的画板
      this.canvas_front = document.getElementById("ctx_front");
      // 用于生成绘制后图片的画板
      this.canvas_back = document.getElementById("ctx_back");
      // 底图画板，橡皮擦除时获取像素放到绘制画板中，达到不擦出底图的效果
      this.canvas_base = document.getElementById("ctx_base");

      this.ctx_base = this.canvas_base.getContext("2d");
      this.ctx_front = this.canvas_front.getContext("2d");
      this.ctx_back = this.canvas_back.getContext("2d");
      this.ctx_front.strokeStyle = this.defaultColor;
      const img = new Image();
      img.src = this.baseMap;
      const _this = this;
      img.onload = function () {
        const width = parseInt(this.width);
        const height = parseInt(this.height);
        _this.currentImg.width = width;
        _this.currentImg.height = height;
        _this.canvas_front.width = _this.windowWidth;
        _this.canvas_front.height = _this.windowHeight;
        _this.canvas_back.width = _this.windowWidth;
        _this.canvas_back.height = _this.windowHeight;
        _this.canvas_base.width = _this.windowWidth;
        _this.canvas_base.height = _this.windowHeight;
        // _this.ctx_front.drawImage(this, 0, 0, width, height);
        _this.ctx_back.drawImage(this, 0, 0, width, height);
        _this.ctx_base.drawImage(this, 0, 0, width, height);
      };
    },
    /**
     * 处理放大缩小
     * */
    handleDrawImage() {
      const _this = this;
      const img = new Image();
      const baseImg = new Image();
      img.src = this.currentImg.url;
      baseImg.src = this.baseMap;
      _this.currentImg.width = _this.currentImg.width * this.currentImg.scale;
      _this.currentImg.height = _this.currentImg.height * this.currentImg.scale;
      img.onload = function () {
        _this.canvas_front.width = _this.currentImg.width;
        _this.canvas_front.height = _this.currentImg.height;
        _this.canvas_back.width = _this.currentImg.width;
        _this.canvas_back.height = _this.currentImg.height;
        _this.ctx_front.drawImage(
          this,
          0,
          0,
          _this.currentImg.width,
          _this.currentImg.height
        );
        _this.ctx_back.drawImage(
          this,
          0,
          0,
          _this.currentImg.width,
          _this.currentImg.height
        );
      };
      baseImg.onload = () => {
        _this.canvas_base.width = _this.currentImg.width;
        _this.canvas_base.height = _this.currentImg.height;
        _this.ctx_base.drawImage(
          baseImg,
          0,
          0,
          _this.currentImg.width,
          _this.currentImg.height
        );
      };
    },
    handleBeLarge() {
      this.currentImg.scale = 1;
      this.currentImg.scale += 0.1;
      this.$nextTick(() => {
        this.handleDrawImage();
      });
    },
    handleBeSmall() {
      this.currentImg.scale = 1;
      this.currentImg.scale -= 0.1;
      this.$nextTick(() => {
        this.handleDrawImage();
      });
    },
    /**
     * 保存
     * */
    handleCanvas2Img() {
      const canvas = document.getElementById("ctx_back");
      let imageData = null;
      imageData = this.ctx_back.getImageData(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < imageData.data.length; i += 4) {
        // 当该像素是透明的,则设置成白色
        if (imageData.data[i + 3] === 0) {
          imageData.data[i] = 255;
          imageData.data[i + 1] = 255;
          imageData.data[i + 2] = 255;
          imageData.data[i + 3] = 255;
        }
      }
      this.ctx_back.putImageData(imageData, 0, 0);
      this.$refs.download.href = canvas.toDataURL("image/jpeg");
      this.$refs.download.click();

      // 保存成功后再把背景变回透明
      imageData = this.ctx_back.getImageData(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < imageData.data.length; i += 4) {
        // 当该像素是白色的,则设置成透明
        if (imageData.data[i + 3] === 255) {
          imageData.data[i] = 255;
          imageData.data[i + 1] = 255;
          imageData.data[i + 2] = 255;
          imageData.data[i + 3] = 0;
        }
      }
      this.ctx_back.putImageData(imageData, 0, 0);
    },
    /**
     * 截图
     */
    handleCropScreenImg() {
      const screenShotHandler = new ScreenShort({
        // 是否启用webrtc，值为boolean类型，值为false则使用html2canvas来截图
        enableWebRtc: false,
        // 层级级别，这个要比你页面上其他元素的z-index的值大，不然截不了
        level: 20001,
        completeCallback: this.cropSuccess, // 截图成功完成的回调
        closeCallback: "", // 截图取消的回调
      });
    },
    cropSuccess(res) {
      const img = new Image();
      img.src = res.base64;
      img.onload = () => {
        const canvas = this.convertImageToCanvas(img);
        const url = canvas.toDataURL("image/jpeg");
        this.$refs.download.href = url;
        this.$refs.download.click();
      };
    },
    convertImageToCanvas(image) {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      canvas.getContext("2d").drawImage(image, 0, 0);
      return canvas;
    },
    /**
     * 清除画布
     * */
    handleClearCanvas() {
      this.handleInitCanvas();
    },
    handleFrommatCanvas() {
      this.ctx_front.clearRect(
        0,
        0,
        this.canvas_front.width,
        this.canvas_front.height
      );
    },
    handleDrawCanvas(type) {
      this.canDraw = false;
      let sx, sy, mx, my;
      const text = document.getElementById("text");

      // 鼠标按下
      const mousedown = (e) => {
        this.ctx_front.strokeStyle = this.defaultColor;
        this.ctx_front.lineWidth = this.slide;
        e = e || window.event;
        sx = e.clientX;
        sy = e.clientY;
        const cbx = this.ctx_base.getImageData(
          e.offsetX - this.slide / 2,
          e.offsetY - this.slide / 2,
          this.slide * 2,
          this.slide * 2
        );
        this.ctx_front.moveTo(sx, sy);
        this.canDraw = true;
        switch (type) {
          case 1:
            this.ctx_front.beginPath();
            this.ctx_front.moveTo(sx, sy);
            this.ctx_front.stroke();
            break;
          case 5:
            this.ctx_front.putImageData(
              cbx,
              e.offsetX - this.slide / 2,
              e.offsetY - this.slide / 2
            );
            break;
          case 6:
            this.handleTextBlur();
            this.text = "";
            text.style.fontSize = 14 + this.slide * 10 + "px";
            text.style.color = this.defaultColor;
            text.style.borderColor = this.defaultColor;
            text.style.left = e.offsetX + "px";
            text.style.top = e.offsetY + "px";
            text.style.zIndex = 10008;
            text.style.display = "block";
            this.tl = e.offsetX;
            this.tt = e.offsetY;
            break;
        }
      };
      const mousemove = (e) => {
        e = e || window.event;
        mx = e.clientX;
        my = e.clientY;
        if (this.canDraw) {
          switch (type) {
            case 1:
              this.ctx_front.lineTo(mx, my);
              this.ctx_front.stroke();
              break;
            case 2:
              this.handleFrommatCanvas();
              this.ctx_front.beginPath();
              this.ctx_front.moveTo(sx, sy);
              this.ctx_front.lineTo(mx, my);
              this.ctx_front.stroke();
              break;
            case 3:
              this.handleFrommatCanvas();
              this.ctx_front.beginPath();
              // eslint-disable-next-line no-case-declarations
              const rds = Math.sqrt(
                (sx - 10 - mx) * (sx - 10 - mx) +
                  (sy - 49 - my) * (sy - 49 - my)
              );
              this.ctx_front.arc(sx, sy, rds, 0, Math.PI * 2, false);
              this.ctx_front.stroke();
              break;
            case 4:
              this.handleFrommatCanvas();
              this.ctx_front.beginPath();
              this.ctx_front.moveTo(sx, sy);
              this.ctx_front.lineTo(mx, sy);
              this.ctx_front.lineTo(mx, my);
              this.ctx_front.lineTo(sx, my);
              this.ctx_front.lineTo(sx, sy);
              this.ctx_front.stroke();
              break;
            case 5:
              this.ctx_front.clearRect(mx, my, 50, 50);
              this.ctx_back.clearRect(mx, my, 50, 50);
              this.ctx_base.clearRect(mx, my, 50, 50);
              break;
          }
        }
      };
      const mouseup = () => {
        if (this.canDraw) {
          this.canDraw = false;
          this.ctx_front.closePath();
          if (type !== 6) {
            // console.log("非文字存储");
            this.handleSaveCanvasStore();
          }
        }
      };
      this.canvas_front.onmousedown = (e) => mousedown(e);
      this.canvas_front.onmousemove = (e) => mousemove(e);
      this.canvas_front.onmouseup = (e) => mouseup(e);
      this.canvas_front.onmouseout = (e) => mouseup(e);
      this.canvas_front.onmouseleave = (e) => mouseup(e);
    },
    /**
     * 失焦或者回车绘制文本，框隐藏
     * */
    handleTextBlur() {
      const text = document.getElementById("text");
      this.ctx_front.font = `300 ${text.style.fontSize} sans-serif`;
      this.ctx_front.fillStyle = this.defaultColor;
      this.ctx_front.fillText(this.text, this.tl, this.tt);
      text.style.display = "none";
      this.text = "";
      text.value = "";
      this.handleSaveCanvasStore();
    },
    /**
     * 上一步
     * */
    handlePrev() {
      if (this.currentImg.index > 0) {
        this.nextDis = false;
        this.currentImg.index -= 1;
        this.currentImg.index === 0
          ? (this.prevDis = true)
          : (this.prevDis = false);
        this.currentImg.url = this.canvasStore[this.currentImg.index];
        this.currentImg.scale = 1;
        this.handleDrawImage();
      } else {
        this.prevDis = true;
      }
    },
    /**
     * 下一步
     * */
    handleNext() {
      if (this.currentImg.index < this.canvasStore.length - 1) {
        this.prevDis = false;
        this.currentImg.index += 1;
        this.currentImg.index === this.canvasStore.length - 1
          ? (this.nextDis = true)
          : (this.nextDis = false);
        this.currentImg.url = this.canvasStore[this.currentImg.index];
        this.currentImg.scale = 1;
        this.handleDrawImage();
      } else {
        this.nextDis = true;
      }
    },
    /**
     * 保存绘制
     * */
    handleSaveCanvasStore() {
      const url = this.canvas_front.toDataURL();
      const image = new Image();
      image.src = url;
      image.onload = () => {
        this.ctx_front.clearRect(
          0,
          0,
          this.canvas_front.width,
          this.canvas_front.height
        );
        this.ctx_front.drawImage(image, 0, 0, image.width, image.height);
        this.ctx_back.drawImage(image, 0, 0, image.width, image.height);
        const url2 = this.canvas_back.toDataURL();
        this.currentImg.url = url2;
        this.currentImg.index += 1;
        this.canvasStore.push(url2);
        this.prevDis = false;
      };
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .confirm {
  display: none;
}
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  height: 100%;
  margin-top: 20px !important;
  & > div {
    width: 100%;
    box-sizing: border-box;
  }
  .close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10008;
  }
  .el-dialog__header {
    padding: 0 20px;
    p {
      padding: 20px 0;
      border-bottom: 1px solid #bdbdbd;
    }
    .el-dialog__headerbtn {
      display: none;
    }
  }
  .el-dialog__body {
    padding: 0;
    flex: 1;
    height: 0;
    padding-top: 0;
    position: relative;
    overflow: hidden;

    .d_body {
      height: 100%;
      .iconfont {
        margin-right: 5px;
        color: #606266;
      }
      .board {
        position: relative;
        min-height: 100%;
        canvas {
          // width: 100%;
          // min-height: 100%;
          position: absolute;
          margin: 0 auto;
          left: 0;
          right: 0;
          top: 0;
        }
        #ctx_front {
          z-index: 10005;
        }
        #ctx_back {
          z-index: 10003;
        }
        #ctx_base {
          z-index: 10001;
        }
        #text {
          position: absolute;
          z-index: -1;
          resize: none;
          outline: none;
          border: 1px dashed #eeeeee;
          overflow: hidden;
          background: transparent;
          line-height: 30px;
          display: none;
        }
      }
      .tools {
        width: 100%;
        position: absolute;
        display: flex;
        z-index: 10005;
        background: #ffffff;
        transition: all 0.2s ease-in-out;
      }
      .settings {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10005;
        padding: 5px 10px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid #eeeeee;
        border-top: 0;
        width: auto;
        .tool_item {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          &:not(:last-of-type) {
            margin-right: 25px;
          }
        }
        .go_up {
          margin-right: 0 !important;
        }
        button {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;
          padding: 5px 10px;
          background: #ffffff;
          border: 1px solid #eeeeee;
          outline: none;
          cursor: pointer;
          position: relative;
          flex-wrap: nowrap;
          span {
            white-space: nowrap;
          }
          .el-color-picker {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            .el-color-picker__trigger {
              width: 100%;
              height: 100%;
              opacity: 0;
              filter: alpha(opacity=0);
            }
          }
        }
        .slide {
          width: 150px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          // /deep/ .el-slider {
          .el-slider {
            flex: 1;
            width: 0;
            margin-left: 10px;
            .el-slider__button-wrapper {
              .el-slider__button {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
        .pull {
          position: absolute;
          right: 20px;
          z-index: 10008;
          bottom: -45px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          margin-right: 0;
          .line {
            width: 2px;
            height: 30px;
            background: dodgerblue;
          }
          .round {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: dodgerblue;
            cursor: pointer;
          }
        }
      }
      .bars {
        top: 200px;
        right: 30px;
        z-index: 10005;
        padding: 15px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border: 1px solid #eeeeee;
        width: auto;
        display: flex;
        flex-direction: column;
        .iconfont {
          font-size: 25px;
        }
        .tool_item {
          cursor: pointer;
          &:not(:last-of-type) {
            margin-bottom: 15px;
            border-bottom: 1px solid #dddddd;
            padding-bottom: 10px;
          }
          span {
            font-size: 18px;
          }
          &:hover {
            .iconfont {
              color: dodgerblue;
            }
            span {
              color: dodgerblue;
            }
          }
        }
        .activeTool {
          border-color: dodgerblue !important;
          .iconfont {
            color: dodgerblue;
          }
          span {
            color: dodgerblue;
          }
        }
        .arrow {
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 50%;
          border: 1px solid #606266;
          color: #606266;
          position: absolute;
          left: -15px;
          background: #ffffff;
          top: 50%;
          z-index: 10008;
          transform: translateY(-50%);
          cursor: pointer;
        }
        .el-icon-s-tools {
          left: -20px;
          width: 32px;
          height: 32px;
          line-height: 32px;
          font-size: 20px;
          color: dodgerblue;
          border-color: dodgerblue;
        }
      }
      .hideTools {
        right: -50px;
      }
      .noExpand {
        top: -50px;
      }
    }
  }
  .el-dialog__footer {
    text-align: center !important;
    span {
      button {
        padding-left: 40px;
        padding-right: 40px;
        &:first-of-type {
          margin-right: 50px;
        }
        &:last-of-type {
          margin-left: 50px;
        }
      }
    }
  }
}
</style>
