<template>
  <div class="qk-aixili-fanka">
    <div class="header">
      <img
        :src="aixiliTitleImgSrc"
        :width="`${aixiliTitleImgWidth}px`"
        :height="`${aixiliTitleImgHeight}px`"
        class="header-left"
      />
      <img
        src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"
        class="header-right"
      />
    </div>
    <div
      class="content"
      :style="{
        width: `${aixiliContentWidth}px`,
        height: `${aixiliContentHeight}px`,
        backgroundSize: `${aixiliContentBackgroundSize}`,
        backgroundRepeat: `${aixiliContentBackgroundRepeat}`,
        backgroundImage: `url('${aixiliContentBackgroundImageSrc}')`,
      }"
    >
      <div class="content-header">
        <div class="header-list">
          <div
            class="list-item"
            v-for="(item, index) in aixiliDataContentFankaaa"
            :key="index"
            @click.stop="() => setActivebutton(index)"
          >
            <img
              :src="
                index === activeButton
                  ? item.activeButtonImageSrc
                  : item.inActiveButtonImageSrc
              "
              class="top-item"
            />
          </div>
        </div>
        <div class="header-line"></div>
      </div>
      <div class="content-content">
        <div class="content-bg-money">
          <img
            src="https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425069887.jpg"
            style="width: 200px; height: 235px"
          />
        </div>
        <div
          class="content-main"
          :style="{
            width: `${aixiliContentMainWidth}px`,
            height: `${aixiliContentMainHeight}px`,
            backgroundSize: `${aixiliContentMainBackgroundSize}`,
            backgroundRepeat: `${aixiliContentMainBackgroundRepeat}`,
            backgroundImage: `url('${aixiliContentMainBackgroundImageSrc}')`,
          }"
        >
          <div
            v-for="(it, ind) in aixiliDataContentFankaaa[activeButton]
              .mainCourseList"
            :key="ind"
          >
            <img
              v-if="currentCourseIndex === ind"
              :src="it.mainCourseImageSrc"
              :width="`${it.mainImageWidth}px`"
              :height="`${it.mainImageHeight}px`"
            />
          </div>
          <div class="play-control">
            <i
              class="iconfont icon-houtui icon-button"
              @click.stop="backTo"
            ></i>
            <i
              class="iconfont icon-qianjin1 icon-button"
              @click.stop="forwardTo"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QkAixiliFanka3",
  props: {
    aixiliTitleImgSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694426989838.jpg",
    },
    aixiliTitleImgWidth: {
      type: Number,
      default: 335,
    },
    aixiliTitleImgHeight: {
      type: Number,
      default: 67,
    },
    aixiliContentBackgroundImageSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-68.jpg",
    },
    aixiliContentWidth: {
      type: Number,
      default: 1400,
    },
    aixiliContentHeight: {
      type: Number,
      default: 636,
    },
    aixiliContentBackgroundSize: {
      type: String,
      default: "cover",
    },
    aixiliContentBackgroundRepeat: {
      type: String,
      default: "no-repeat",
    },
    aixiliContentMainBackgroundImageSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425001700.jpg",
    },
    aixiliContentMainWidth: {
      type: Number,
      default: 822,
    },
    aixiliContentMainHeight: {
      type: Number,
      default: 444,
    },
    aixiliContentMainBackgroundSize: {
      type: String,
      default: "cover",
    },
    aixiliContentMainBackgroundRepeat: {
      type: String,
      default: "no-repeat",
    },
    aixiliDataContentFankaaa: {
      type: Array,
      default: () => [
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424706000.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424706000.jpg",
          mainCourseList: [
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
          ],
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424742772.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424742772.jpg",
          mainCourseList: [
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
          ],
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424819504.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424819504.jpg",
          mainCourseList: [
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
          ],
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424878100.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424878100.jpg",
          mainCourseList: [
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
          ],
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424908731.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424908731.jpg",
          mainCourseList: [
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
          ],
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424948902.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694424948902.jpg",
          mainCourseList: [
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
            {
              mainCourseImageSrc:
                "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425037266.jpg",
              mainImageWidth: 695,
              mainImageHeight: 360,
            },
          ],
        },
      ],
    },
  },
  data() {
    return {
      activeButton: 0,
      currentCourseIndex: 0,
    };
  },
  methods: {
    setActivebutton(index) {
      this.activeButton = index;
      this.currentCourseIndex = 0;
    },
    backTo() {
      if (this.currentCourseIndex > 0) {
        --this.currentCourseIndex;
      }
    },
    forwardTo() {
      if (
        this.currentCourseIndex + 1 <
        this.aixiliDataContentFankaaa[this.activeButton].mainCourseList.length
      ) {
        ++this.currentCourseIndex;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.qk-aixili-fanka {
  width: 100%;
  height: 100%;
  background: #ffffff;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .header-left {
      margin-left: 84px;
      margin-top: 58px;
    }
    .header-right {
      width: 272px;
      height: 105px;
      margin-top: 34px;
      margin-right: 120px;
    }
  }
  .content {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .content-header {
      width: 100%;
      padding-top: 34px;
      padding-left: 75px;
      .header-list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .list-item {
          width: 190px;
          height: 50px;
          margin-right: 20px;
          .top-item {
            width: 100%;
            height: 100%;
          }
        }
      }
      .header-line {
        width: calc(100% - 92px);
        height: 3.35px;
        background: #ffffff;
        margin-top: 20px;
      }
    }
    .content-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .content-bg-money {
        position: absolute;
        bottom: 36px;
        left: 140px;
      }
      .content-main {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .play-control {
          position: absolute;
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          bottom: 40px;
          .icon-button {
            background: transparent;
            border: none;
            width: 125px;
            font-size: 48px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
