<template>
  <div class="qk-aixili-fanka">
    <div class="header">
      <img
        :src="aixiliTitleImgSrc"
        :width="`${aixiliTitleImgWidth}px`"
        :height="`${aixiliTitleImgHeight}px`"
        class="header-left"
      />
      <img
        src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"
        class="header-right"
      />
    </div>
    <div
      class="content"
      :style="{
        width: `${aixiliContentWidth}px`,
        height: `${aixiliContentHeight}px`,
        backgroundSize: `${aixiliContentBackgroundSize}`,
        backgroundRepeat: `${aixiliContentBackgroundRepeat}`,
        backgroundImage: `url('${aixiliContentBackgroundImageSrc}')`,
        position: 'relative',
      }"
    >
      <div class="content-header" @drop="dropHeader" @dragover.prevent>
        <div class="header-list">
          <div
            class="list-item"
            v-for="(item, index) in aixiliDataContentFankaaaaa"
            :key="index"
            :id="`listItem${index}`"
            :style="{
              backgroundImage: `url(${item.inActiveButtonImageSrc})`,
              backgroundSize: '190px  50px',
              backgroundRepeat: 'no-repeat',
            }"
          >
            <img
              :id="`dragImg${index}`"
              :src="item.activeButtonImageSrc"
              draggable="true"
              style="width: 190px; height: 50px"
              @dragstart="dragStart"
            />
          </div>
        </div>
        <div class="header-line"></div>
      </div>
      <div
        class="content-content"
        @drop="dropContent"
        @dragover.prevent
        :style="{
          backgroundImage: `url(${aixiliDataContentFankaaaaa[activeButton].mainCourseImageSrc})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: `${aixiliDataContentFankaaaaa[activeButton].mainImageWidth}px ${aixiliDataContentFankaaaaa[activeButton].mainImageHeight}px`,
        }"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QkAixiliFanka5",
  props: {
    aixiliTitleImgSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694426989838.jpg",
    },
    aixiliTitleImgWidth: {
      type: Number,
      default: 335,
    },
    aixiliTitleImgHeight: {
      type: Number,
      default: 67,
    },
    aixiliContentBackgroundImageSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-68.jpg",
    },
    aixiliContentWidth: {
      type: Number,
      default: 1400,
    },
    aixiliContentHeight: {
      type: Number,
      default: 636,
    },
    aixiliContentBackgroundSize: {
      type: String,
      default: "cover",
    },
    aixiliContentBackgroundRepeat: {
      type: String,
      default: "no-repeat",
    },
    aixiliDataContentFankaaaaa: {
      type: Array,
      default: () => [
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685644629.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685783746.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694686206021.jpg",
          mainImageWidth: 1232,
          mainImageHeight: 368,
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685676258.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685783746.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694686206021.jpg",
          mainImageWidth: 1232,
          mainImageHeight: 368,
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685676258.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685783746.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694686206021.jpg",
          mainImageWidth: 1232,
          mainImageHeight: 368,
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685754552.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685783746.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694686206021.jpg",
          mainImageWidth: 1232,
          mainImageHeight: 368,
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685783746.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685783746.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694686206021.jpg",
          mainImageWidth: 1232,
          mainImageHeight: 368,
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685816046.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694685783746.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan5%2F1694686206021.jpg",
          mainImageWidth: 1232,
          mainImageHeight: 368,
        },
      ],
    },
  },
  data() {
    return {
      activeButton: 1,
      startOffsetX: 0,
      startOffsetY: 0,
    };
  },
  methods: {
    dragStart(e) {
      e.dataTransfer.setData("tempImage", e.target.id);
      this.startOffsetX = e.offsetX;
      this.startOffsetY = e.offsetY;
    },
    dropHeader(e) {
      e.preventDefault();
      let id = e.dataTransfer.getData("tempImage");
      const index = parseInt(id.replace("dragImg", ""));

      const listItemId = id.replace("dragImg", "listItem");
      const listItem = document.getElementById(listItemId);
      const idElem = document.getElementById(id);
      idElem.removeAttribute("style");
      idElem.style.width = "190px";
      idElem.style.height = "50px";
      listItem.appendChild(idElem);
    },
    dropContent(e) {
      e.preventDefault();
      const id = e.dataTransfer.getData("tempImage");
      const index = id.replace("dragImg", "");
      const idElem = document.getElementById(id);
      idElem.style.position = "absolute";
      idElem.style.left = `${e.layerX - this.startOffsetX}px`;
      idElem.style.top = `${e.layerY - this.startOffsetY}px`;
      e.target.appendChild(idElem);
    },
  },
};
</script>
<style lang="scss" scoped>
.qk-aixili-fanka {
  width: 100%;
  height: 100%;
  background: #ffffff;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .header-left {
      margin-left: 84px;
      margin-top: 58px;
    }
    .header-right {
      width: 272px;
      height: 105px;
      margin-top: 34px;
      margin-right: 120px;
    }
  }
  .content {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .content-header {
      width: 100%;
      padding-top: 34px;
      padding-left: 75px;
      .header-list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .list-item {
          width: 190px;
          height: 50px;
          margin-right: 20px;
        }
      }
      .header-line {
        width: calc(100% - 92px);
        height: 3px;
        background: #ffffff;
        margin-top: 20px;
      }
    }
    .content-content {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
