import axios from "axios";
import { getToken } from "@/utils/auth";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.headers["Content-Type"] = "application/json;charse=UTF-8";

axios.defaults.timeout = 30000;

axios.interceptors.request.use(
  (config) => {
    config.headers.access_token = getToken();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      this.$message.error(
        response.data.message ||
          response.data.msg ||
          response.data.errMsgv ||
          ""
      );
      return Promise.reject(response);
    }
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "错误请求";
          break;
        case 401:
          err.message = "请重新登录";
          break;
        case 403:
          err.message = "没有访问权限，拒绝访问";
          break;
        case 404:
          err.message = "请求错误,未找到该资源";
          break;
        case 405:
          err.message = "请求方法未允许";
          break;
        case 408:
          err.message = "请求超时";
          break;
        case 500:
          err.message = err.response.data.message;
          break;
        case 501:
          err.message = "网络未实现";
          break;
        case 502:
          err.message = "网络错误";
          break;
        case 503:
          err.message = "服务不可用";
          break;
        case 504:
          err.message = "网络超时";
          break;
        default:
          err.message = `连接错误${err.response.msg}`;
      }
    } else {
      err.message = "";
    }
    // this.$message.error(err.message || err.response.msg || "");
    return Promise.reject(err.response);
  }
);

export default {
  get(url, param, header) {
    return axios({
      method: "get",
      url,
      headers: {
        ...(header || {}),
      },
      params: param || {},
    });
  },
  post(url, param, header) {
    return axios({
      method: "post",
      url,
      headers: {
        ...(header || {}),
        "Content-Type": "application/json;charse=UTF-8",
      },
      data: param || {},
    });
  },
  postFormData(url, params, header) {
    return axios({
      method: "post",
      url,
      headers: {
        ...(header || {}),
        "Content-Type": "multipart/form-data",
      },
      data: params,
    });
  },
  put(url, param, header) {
    return axios({
      method: "put",
      url,
      headers: {
        ...(header || {}),
        "Content-Type": "application/json;charse=UTF-8",
      },
      data: param || {},
    });
  },
  delete(url, param, header) {
    return axios({
      method: "delete",
      url,
      headers: {
        ...(header || {}),
      },
      params: param || {},
    });
  },
};
