/* eslint-disable no-unused-vars */
export default {
  methods: {
    _event_find_webactive_link(eventData) {
      return new Promise((resolve) => {
        if (eventData.url) {
          window.location.href = eventData.url;
        }
        resolve();
      });
    },
    _event_find_webactive_page(eventData) {
      return new Promise((resolve) => {
        resolve();
      });
    },
    _event_find_webactive_tel(eventData) {
      return new Promise((resolve) => {
        window.location.href = "tel:" + eventData.dailPhoneNumber;
        resolve();
      });
    },
    _event_find_webactive_sms(eventData) {
      return new Promise((resolve) => {
        location.href = "sms:" + eventData.smsPhoneNumber;
        resolve();
      });
    },
    _event_find_webactive_playAudio(eventData) {
      return new Promise((resolve) => {
        resolve();
      });
    },
    _event_find_webactive_playVideo(eventData) {
      return new Promise((resolve) => {
        resolve();
      });
    },
    _event_find_webactive_show(eventData) {
      return new Promise((resolve) => {
        resolve();
      });
    },
  },
};
