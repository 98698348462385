/* eslint-disable camelcase */
import Blank from "./blank";
import ChartBar from "./chart-bar";
import ChartLine from "./chart-line";
import ChartPie from "./chart-pie";
import ChartScatter from "./chart-scatter";
import Text from "./text";
import Icon from "./icon";
import Image from "./image";
import ImageCarousel from "./image-carousel";
import Qrcode from "./qrcode";
import Video from "./video";
import Map from "./map";
import Notice from "./notice";
import Progress from "./progress";
import RectangleBorder from "./rectangle-border";
import Iframe from "./iframe";
import Button from "./button";
import Music from "./music";
import LongText from "./long-text";
import AixiliFanka from "./aixili/fanka";
import AixiliFanka2 from "./aixili/fanka2";
import AixiliFanka3 from "./aixili/fanka3";
import AixiliFanka4 from "./aixili/fanka4";
import AixiliFanka5 from "./aixili/fanka5";
import AixiliFanka6 from "./aixili/fanka6";
import LuckyWheel from "./lucky-wheel";
import LuckyGrid from "./lucky-grid";

// 所有组件列表
const components = [
  Blank,
  ChartBar,
  ChartLine,
  ChartPie,
  ChartScatter,
  Text,
  Qrcode,
  Video,
  Map,
  Notice,
  Progress,
  Icon,
  ImageCarousel,
  Image,
  RectangleBorder,
  Iframe,
  Button,
  Music,
  LongText,
  AixiliFanka,
  AixiliFanka2,
  AixiliFanka3,
  AixiliFanka4,
  AixiliFanka5,
  AixiliFanka6,
  LuckyWheel,
  LuckyGrid,
];
const install = function (Vue) {
  if (install.installed) return;
  install.installed = true;
  components.map((component) => Vue.component(component.name, component));
};

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

const _qk_register_components_object = {};
components.forEach((item) => {
  _qk_register_components_object[item.name] = item;
});

export {
  Blank,
  ChartBar,
  ChartLine,
  ChartPie,
  ChartScatter,
  Text,
  Icon,
  Image,
  ImageCarousel,
  Qrcode,
  Video,
  Map,
  Notice,
  Progress,
  RectangleBorder,
  Iframe,
  Button,
  Music,
  LongText,
  AixiliFanka,
  AixiliFanka2,
  AixiliFanka3,
  AixiliFanka4,
  AixiliFanka5,
  AixiliFanka6,
  LuckyWheel,
  LuckyGrid,
  _qk_register_components_object,
};

export default {
  install,
};
