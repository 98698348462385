<template>
  <div class="qk-long-text" v-html="longText"></div>
</template>

<script>
export default {
  name: "QkLongText",
  props: {
    longText: {
      type: String,
      default: "这是一段长文本文字\n长文本支持换行\n又一行文字",
    },
  },
  data() {
    return {};
  },
};
</script>
