import $axios from "@/service/httpServer";

export const getSchedule = (p) =>
  $axios.get("/tony-teaching/readingPlan/query-page", p);

export const getCourse = (p) =>
  $axios.get("/tony-teaching/course_ware/queryByPage", p);

export const getLoginCode = (p) => $axios.get("/tony-common/check-code", p);

export const login = (p) => $axios.get("/tony-admin/user/login", p);
