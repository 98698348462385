var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qk-aixili-fanka"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"header-left",attrs:{"src":_vm.aixiliTitleImgSrc,"width":`${_vm.aixiliTitleImgWidth}px`,"height":`${_vm.aixiliTitleImgHeight}px`}}),_c('img',{staticClass:"header-right",attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"}})]),_c('div',{staticClass:"content",style:({
      width: `${_vm.aixiliContentWidth}px`,
      height: `${_vm.aixiliContentHeight}px`,
      backgroundSize: `${_vm.aixiliContentBackgroundSize}`,
      backgroundRepeat: `${_vm.aixiliContentBackgroundRepeat}`,
      backgroundImage: `url('${_vm.aixiliContentBackgroundImageSrc}')`,
    })},[_c('div',{staticClass:"content-header"},[_c('div',{staticClass:"header-list"},_vm._l((_vm.aixiliDataContentFankaaa),function(item,index){return _c('div',{key:index,staticClass:"list-item",on:{"click":function($event){$event.stopPropagation();return (() => _vm.setActivebutton(index)).apply(null, arguments)}}},[_c('img',{staticClass:"top-item",attrs:{"src":index === _vm.activeButton
                ? item.activeButtonImageSrc
                : item.inActiveButtonImageSrc}})])}),0),_c('div',{staticClass:"header-line"})]),_c('div',{staticClass:"content-content"},[_vm._m(0),_c('div',{staticClass:"content-main",style:({
          width: `${_vm.aixiliContentMainWidth}px`,
          height: `${_vm.aixiliContentMainHeight}px`,
          backgroundSize: `${_vm.aixiliContentMainBackgroundSize}`,
          backgroundRepeat: `${_vm.aixiliContentMainBackgroundRepeat}`,
          backgroundImage: `url('${_vm.aixiliContentMainBackgroundImageSrc}')`,
        })},[_vm._l((_vm.aixiliDataContentFankaaa[_vm.activeButton]
            .mainCourseList),function(it,ind){return _c('div',{key:ind},[(_vm.currentCourseIndex === ind)?_c('img',{attrs:{"src":it.mainCourseImageSrc,"width":`${it.mainImageWidth}px`,"height":`${it.mainImageHeight}px`}}):_vm._e()])}),_c('div',{staticClass:"play-control"},[_c('i',{staticClass:"iconfont icon-houtui icon-button",on:{"click":function($event){$event.stopPropagation();return _vm.backTo.apply(null, arguments)}}}),_c('i',{staticClass:"iconfont icon-qianjin1 icon-button",on:{"click":function($event){$event.stopPropagation();return _vm.forwardTo.apply(null, arguments)}}})])],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-bg-money"},[_c('img',{staticStyle:{"width":"200px","height":"235px"},attrs:{"src":"https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694425069887.jpg"}})])
}]

export { render, staticRenderFns }