<template>
  <div class="qk-aixili-fanka">
    <div class="header">
      <img
        :src="aixiliTitleImgSrc"
        :width="aixiliTitleImgWidth"
        :height="aixiliTitleImgHeight"
        class="header-left"
      />
      <img
        src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"
        class="header-right"
      />
    </div>
    <div
      class="content"
      :style="{
        width: `${aixiliContentWidth}px`,
        height: `${aixiliContentHeight}px`,
        backgroundSize: `${aixiliContentBackgroundSize}`,
        backgroundRepeat: `${aixiliContentBackgroundRepeat}`,
        backgroundImage: `url('${aixiliContentBackgroundImageSrc}')`,
      }"
    >
      <div class="content-left">
        <div class="left-top">
          <div class="top-title">
            <img
              :src="aixiliDataContentFanka.subTitleImgSrc"
              :width="aixiliDataContentFanka.subTitleImgWidth"
              :height="aixiliDataContentFanka.subTitleImgHeight"
            />
          </div>
        </div>
        <div class="left-middle">
          <img
            src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-77.jpg"
          />
        </div>
      </div>
      <div class="content-center">
        <div class="center-top">
          <div class="top-left">
            <img
              :src="aixiliDataContentFanka.mainTitleImgSrc"
              :width="aixiliDataContentFanka.mainTitleImgWidth"
              :height="aixiliDataContentFanka.mainTitleHeight"
              class="top-title"
            />
          </div>
          <div class="top-right">
            <div style="margin-right: 10px" @click.stop="addValue">
              <img
                src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-74.jpg"
              />
            </div>
            <div style="margin-right: 20px" @click.stop="decValue">
              <img
                src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-75.jpg"
              />
            </div>
            <div class="right-button" @click.stop="saveValue">
              <img
                src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-76.jpg"
              />
            </div>
          </div>
        </div>
        <div class="center-content">
          <div
            v-for="(item, index) in dataContent"
            :key="index"
            class="content-flex"
          >
            <img
              v-if="item.type === 'empty'"
              src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-89.jpg"
            />
            <div v-else class="flex-input">
              <el-input style="width: 90%"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QkAixiliFanka",
  props: {
    aixiliTitleImgSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-69.jpg",
    },
    aixiliTitleImgWidth: {
      type: Number,
      default: 92,
    },
    aixiliTitleImgHeight: {
      type: Number,
      default: 41,
    },
    aixiliContentBackgroundImageSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-68.jpg",
    },
    aixiliContentWidth: {
      type: Number,
      default: 1400,
    },
    aixiliContentHeight: {
      type: Number,
      default: 636,
    },
    aixiliContentBackgroundSize: {
      type: String,
      default: "cover",
    },
    aixiliContentBackgroundRepeat: {
      type: String,
      default: "no-repeat",
    },
    aixiliDataContentFanka: {
      type: Object,
      default: () => {
        return {
          subTitleImgSrc:
            "https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-79.jpg",
          subTitleImgWidth: 198,
          subTitleImgHeight: 35,
          mainTitleImgSrc:
            "https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-80.jpg",
          mainTitleImgWidth: 437,
          mainTitleImgHeight: 70,
        };
      },
    },
  },

  data() {
    return {
      dataContent: [
        {
          type: "empty",
          value: "",
          status: "",
        },
        {
          type: "empty",
          value: "",
          status: "",
        },
        {
          type: "empty",
          value: "",
          status: "",
        },
        {
          type: "empty",
          value: "",
          status: "",
        },
        {
          type: "empty",
          value: "",
          status: "",
        },
        {
          type: "empty",
          value: "",
          status: "",
        },
        {
          type: "empty",
          value: "",
          status: "",
        },
        {
          type: "empty",
          value: "",
          status: "",
        },
      ],
    };
  },
  computed: {},
  methods: {
    addValue() {
      const ind = this.dataContent.findIndex((item) => item.type === "empty");
      if (ind !== -1) {
        this.dataContent[ind].type = "input";
        this.dataContent[ind].value = "";
        this.dataContent[ind].status = "";
      }
    },
    decValue() {
      const ind = this.dataContent.findIndex((item) => item.type === "empty");
      if (ind !== 0 && ind !== -1) {
        this.dataContent[ind - 1].type = "empty";
        this.dataContent[ind].value = "";
        this.dataContent[ind].status = "";
      } else if (ind === -1) {
        this.dataContent[7].type = "empty";
        this.dataContent[7].value = "";
        this.dataContent[7].status = "";
      }
    },
    saveValue() {},
  },
};
</script>
<style lang="scss" scoped>
.qk-aixili-fanka {
  width: 100%;
  height: 100%;
  background: #ffffff;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .header-left {
      margin-left: 110px;
      margin-top: 68px;
    }
    .header-right {
      width: 269px;
      height: 95px;
      margin-top: 40px;
      margin-right: 90px;
    }
  }
  .content {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .content-left {
      width: 440px;
      .left-top {
        .top-title {
          width: 250px;
          height: 52px;
          margin-left: 80px;
          margin-top: 72px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background-image: url("https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-78.jpg");
          background-repeat: no-repeat;
        }
      }
      .left-middle {
        width: 370px;
        height: 408px;
        margin-top: 55px;
        margin-left: 45px;
      }
    }
    .content-center {
      width: 810px;
      .center-top {
        margin-top: 105px;
        margin-left: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .top-left {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          .top-title {
            margin-left: 14px;
            margin-bottom: 28px;
          }
        }
        .top-right {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }
      }
      .center-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .content-flex {
          width: 190px;
          height: 160px;
          margin-left: 10px;
          margin-bottom: 20px;
          .flex-input {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-83.jpg);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
