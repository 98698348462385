<template>
  <div class="qk-progress">
    <div class="qk-progress-outer" :style="outerStyle">
      <div class="qk-progress-inner" :style="innerStyle">
        <div
          :class="[
            'qk-progress-bg',
            { 'qk-progress-success-bg': progress >= 100 },
          ]"
          :style="progressStyle"
        ></div>
      </div>
    </div>
    <svg
      v-if="showInfo && progress >= 100"
      class="qk-progress-success"
      viewBox="64 64 896 896"
      data-icon="check-circle"
      aria-hidden="true"
      focusable="false"
    >
      <path
        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"
      ></path>
    </svg>
    <p
      v-if="showNumber && !(showInfo && progress >= 100)"
      class="qk-progress-text"
    >
      {{ progress >= 100 ? 100 : progress }}%
    </p>
  </div>
</template>
<script>
export default {
  name: "QkProgress", // 这个名字很重要，它就是未来的标签名 <qk-progress></qk-progress>
  props: {
    width: {
      // 进度条总宽度
      type: [String, Number],
      default: 200,
    },
    progress: {
      // 当前进度
      type: [String, Number],
      default: 30,
    },
    borderRadius: {
      // 边框圆角大小
      type: [String, Number],
      default: 100,
    },
    strokeWidth: {
      // 进度条线的宽度
      type: [String, Number],
      default: 8,
    },
    showNumber: {
      // 是否显示进度数值
      type: Boolean,
      default: true,
    },
    showInfo: {
      // 是否显示状态图标
      type: Boolean,
      default: true,
    },
  },
  computed: {
    outerStyle() {
      return this.width
        ? {
            width: `${this.width}px`,
          }
        : {};
    },
    innerStyle() {
      return {
        borderRadius: `${this.borderRadius}px`,
      };
    },
    progressStyle() {
      return {
        width: `${this.progress >= 100 ? 100 : this.progress}%`,
        height: `${this.strokeWidth}px`,
        borderRadius: `${this.borderRadius}px`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.qk-progress {
  height: 24px;
  margin: 0 auto;
  display: flex;
  .qk-progress-outer {
    width: 100%;
    .qk-progress-inner {
      display: inline-block;
      width: 100%;
      background: #f5f5f5;
      // border-radius: 100px;
      .qk-progress-bg {
        // background: #1890FF;
        background: linear-gradient(
          to right,
          rgb(16, 142, 233),
          rgb(135, 208, 104)
        );
        // border-radius: 100px;
        transition: all 0.3s cubic-bezier(0.08, 0.82, 0.17, 1);
      }
      .qk-progress-success-bg {
        background: #52c41a;
      }
    }
  }
  .qk-progress-success {
    width: 16px;
    height: 16px;
    fill: #52c41a;
    margin-left: 8px;
    margin-top: 4px;
  }
  .qk-progress-text {
    font-size: 16px;
    line-height: 24px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
