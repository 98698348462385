<template>
  <div class="qk-text">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "QkText",
  props: {
    text: {
      type: String,
      default: "这是一段文字",
    },
    fontSize: {
      type: Number,
      default: 16,
    },
    fontFamily: {
      type: String,
      default: "morenziti",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
