var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LuckyGrid',{ref:"LuckDraw",staticStyle:{"width":"310px","height":"330px"},attrs:{"blocks":[
    { padding: '1px', background: '#e2cea3', borderRadius: '13px' },
    { padding: '5px 0px', background: '#f3ecdc', borderRadius: '13px' },
    { padding: '1px', background: '#e2cea3', borderRadius: '8px' },
    { padding: '15px 10px', background: '#fffcf5', borderRadius: '8px' },
  ],"button":{
    x: 1,
    y: 1,
    background: 'rgba(0, 0, 0, 0)',
    imgs: [
      {
        src: 'https://static-oss.abiefamily.com/find-webactive/component/lucky-grid/btn.png',
        width: '90%',
        top: '5%',
      },
    ],
  },"prizes":_vm.prizes,"default-style":{
    background: '#ffefd6',
    borderRadius: '5px',
    fontColor: '#755c28',
    fontSize: '10px',
    lineHeight: '12px',
  },"active-style":{
    background: '#de7247',
    fontColor: '#ffefd6',
  }},on:{"start":_vm.startCallBack,"end":_vm.endCallBack}})
}
var staticRenderFns = []

export { render, staticRenderFns }