<!--矩形边框-->
<template>
  <div class="qk-rectangle-border"></div>
</template>

<script>
export default {
  name: "QkRectangleBorder", // 这个名字很重要，它就是未来的标签名
};
</script>

<style lang="scss" scoped></style>
