<template>
  <div class="qk-chart-line">
    <div ref="workspace"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "QkChartLine", // 这个名字很重要，它就是未来的标签名 <qk-chart-line></qk-chart-line>
  props: {
    title: {
      // 标题
      type: String,
      default: "折线图",
    },
    width: {
      // 宽度
      type: [String, Number],
      default: 375,
    },
    height: {
      // 高度
      type: [String, Number],
      default: 300,
    },
    dataSrc: {
      // 数据源
      type: Object,
      default: () => {
        return {
          xAxis: {
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
          yAxis: {},
          series: [
            {
              type: "line",
              data: [23, 24, 18, 25, 27, 28, 25],
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    // outerStyle() {
    //   return this.width
    //     ? {
    //         width: `${this.width}px`,
    //       }
    //     : {}
    // },
  },
  watch: {
    title(val) {
      // 绘制标题
      this.chart &&
        this.chart.setOption({
          title: {
            text: val || "",
          },
        });
    },
    width(val) {
      // 重新绘制
      this.chart &&
        this.chart.resize({
          width: val,
          height: this.height,
        });
    },
    height(val) {
      // 重新绘制
      this.chart &&
        this.chart.resize({
          width: this.width,
          height: val,
        });
    },
    dataSrc() {
      // 重新绘制
      this.chart && this.init();
    },
  },
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    this.chart = echarts.init(this.$refs.workspace, null, {
      width: this.width,
      height: this.height,
    });
    this.init();
  },
  methods: {
    init() {
      // 绘制图表
      this.chart.setOption({
        title: {
          text: this.title,
        },
        tooltip: {},
        ...this.dataSrc,
      });
    },
  },
};
</script>
<!-- <style lang="scss" scoped>
.qk-chart-line {
}
</style> -->
