<template>
  <div class="select-wrap m-r-40">
    <div
      :class="['select-current flex-around-center', classCurrent]"
      @click="show = !show"
    >
      <div style="min-width: 100px">
        {{ getTypesName(type, modelValue) }}
      </div>
      <img
        v-if="icon"
        src="@/assets/icon/icon-bottom.png"
        class="icon-select"
        alt="icon"
      />
    </div>
    <div v-show="show" class="select-list">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="['select-item', classItem]"
        @click="handlerSelect(item)"
      >
        {{ rangeKey ? item[rangeKey] : type ? getTypesName(type, item) : item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  model: {
    // 建议显示把这个写上
    event: "update:modelValue",
    prop: "modelValue",
  },
  props: {
    classWrap: {
      type: String,
      default: "",
    },
    classCurrent: {
      type: String,
      default: "bgBlue",
    },
    classItem: {
      type: String,
      default: "bgBlue",
    },
    icon: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    rangeKey: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    // eslint-disable-next-line vue/require-default-prop
    modelValue: {
      type: [String, Number],
    },
  },
  data() {
    return { show: false };
  },
  methods: {
    handlerSelect(item) {
      this.show = false;
      this.$emit(
        "update:modelValue",
        this.rangeKey ? item[this.rangeKey] : item
      );
      this.$emit("select", this.rangeKey ? item[this.rangeKey] : item);
    },
    getTypesName(type, num) {
      if (type === "Term ") {
        return `Term ${
          [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
          ][num - 1]
        }`;
      } else {
        return type + num;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bgBlue {
  @include bgColor(left, center, 80px, #28a7e1, #1f80c4);
}

.bgGreenYellow {
  @include bgColor(left, center, 110px, #86a248, #edaf2e);
}

.bgBlueGreen {
  @include bgColor(left, center, 110px, #1071ba, #529b4f);
}

.select-wrap {
  position: relative;
  min-width: 120px;
  height: 54px;

  color: #fff;
  font-size: 28px;
  z-index: 3;
  cursor: pointer;

  .select-current {
    position: relative;
    z-index: 10;
    padding: 0 32px;
    height: 54px;
    border-radius: 54px;

    .icon-select {
      margin-left: 10px;
      width: 22px;
      height: 14px;
    }
  }

  .select-list {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    padding: 84px 20px 30px;
    border-radius: 54px 54px 30px 30px;
    width: calc(100% - 40px);
    background-color: rgba(255, 255, 255, 0.6);
    text-align: center;
    line-height: 54px;
    font-size: 20px;
    max-height: 700px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    .select-item {
      border-radius: 54px;
      margin-bottom: 20px;
      padding: 0 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
