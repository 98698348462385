var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qk-aixili-fanka"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"header-left",attrs:{"src":_vm.aixiliTitleImgSrc,"width":`${_vm.aixiliTitleImgWidth}px`,"height":`${_vm.aixiliTitleImgHeight}px`}}),_c('img',{staticClass:"header-right",attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"}})]),_c('div',{staticClass:"content",style:({
      width: `${_vm.aixiliContentWidth}px`,
      height: `${_vm.aixiliContentHeight}px`,
      backgroundSize: `${_vm.aixiliContentBackgroundSize}`,
      backgroundRepeat: `${_vm.aixiliContentBackgroundRepeat}`,
      backgroundImage: `url('${_vm.aixiliContentBackgroundImageSrc}')`,
    })},[_c('div',{staticClass:"content-left"},[_c('img',{staticClass:"left-img",attrs:{"src":"https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694502812731.jpg"}}),_c('div',{staticClass:"left-select"},[_c('div',{staticClass:"click-collapse",on:{"click":function($event){$event.stopPropagation();return _vm.handleCollapseClick.apply(null, arguments)}}},[_c('img',{attrs:{"src":"https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694502873944.jpg","width":"230px","height":"54px"}}),(!_vm.collapse)?_c('div',{staticClass:"select-list"},_vm._l((_vm.aixiliDataContentFankaaaa),function(item,index){return _c('div',{key:index},[(index != _vm.activeButton)?_c('div',{key:index,staticClass:"list-item",on:{"click":function($event){$event.stopPropagation();return _vm.handleButtonClick(index)}}},[_c('img',{attrs:{"src":item.activeButtonImageSrc}})]):_vm._e()])}),0):_vm._e()])])]),_c('div',{staticClass:"content-center"},[_c('div',{staticClass:"center-main",style:({
          width: `${_vm.aixiliContentMainWidth}px`,
          height: `${_vm.aixiliContentMainHeight}px`,
          backgroundSize: `${_vm.aixiliContentMainBackgroundSize}`,
          backgroundRepeat: `${_vm.aixiliContentMainBackgroundRepeat}`,
          backgroundImage: `url('${_vm.aixiliContentMainBackgroundImageSrc}')`,
        })},_vm._l((_vm.aixiliDataContentFankaaaa),function(item,index){return _c('div',{key:index,staticClass:"main-content"},[(index == _vm.activeButton)?_c('div',{staticClass:"main-top"},[_c('div',{staticClass:"top-top"},[_c('img',{attrs:{"src":"https://static-oss.abiefamily.com/dev/abstatic-webactive/image/4a2fc8457cc70bbf5cb27cbeaac97cc5.jpg","width":"56px","height":"56px"},on:{"click":function($event){$event.stopPropagation();return _vm.nextPage.apply(null, arguments)}}})]),_c('div',{staticClass:"top-bottom"},[_c('img',{attrs:{"src":_vm.viewContent.imageSrc,"width":`${_vm.viewContent.imageWidth}px`,"height":`${_vm.viewContent.imageHeight}px`}})])]):_vm._e(),(index == _vm.activeButton)?_c('div',{staticClass:"main-bottom"},[_c('div',{staticClass:"bottom-left",on:{"click":function($event){$event.stopPropagation();return (() => (_vm.showTips = !_vm.showTips)).apply(null, arguments)}}},[_c('img',{attrs:{"src":"https://static-oss.abiefamily.com/dev/abstatic-webactive/image/013af3e49a35901c7b1971a4bd2b8898.jpg","width":"83px","height":"100px"}})]),(_vm.showTips)?_c('div',{staticClass:"bottom-right"},[_c('img',{attrs:{"src":item.tipsSection.tipsImageSrc,"width":`${item.tipsSection.tipsImageWidth}px`,"height":`${item.tipsSection.tipsImageHeight}px`}})]):_vm._e()]):_vm._e()])}),0)]),_c('div',{staticClass:"content-right"},[_c('div',{style:({
          width: `${_vm.aixiliDataContentFankaaaa[_vm.activeButton].mainCourseAudioIconWidth}px`,
          height: `${_vm.aixiliDataContentFankaaaa[_vm.activeButton].mainCourseAudioIconHeight}px`,
        })},[_c('music',{attrs:{"music-options":_vm.viewAudio}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }