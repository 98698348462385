var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qk-aixili-fanka"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"header-left",attrs:{"src":_vm.aixiliTitleImgSrc,"width":`${_vm.aixiliTitleImgWidth}px`,"height":`${_vm.aixiliTitleImgHeight}px`}}),_c('img',{staticClass:"header-right",attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"}})]),_c('div',{staticClass:"content",style:({
      width: `${_vm.aixiliContentWidth}px`,
      height: `${_vm.aixiliContentHeight}px`,
      backgroundSize: `${_vm.aixiliContentBackgroundSize}`,
      backgroundRepeat: `${_vm.aixiliContentBackgroundRepeat}`,
      backgroundImage: `url('${_vm.aixiliContentBackgroundImageSrc}')`,
    })},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"left-top"},_vm._l((_vm.aixiliDataContentFankaa),function(item,index){return _c('div',{key:index,on:{"click":function($event){$event.stopPropagation();return (() => _vm.selectButtonActive(index)).apply(null, arguments)}}},[_c('img',{staticClass:"top-item",attrs:{"src":index === _vm.activeButton
                ? item.activeButtonImageSrc
                : item.inActiveButtonImageSrc}})])}),0),_c('div',{staticClass:"left-middle"},[_c('img',{attrs:{"src":_vm.aixiliDataContentFankaa[_vm.activeButton].leftImageSrc,"width":`${_vm.aixiliDataContentFankaa[_vm.activeButton].leftImageWidth}px`,"height":`${_vm.aixiliDataContentFankaa[_vm.activeButton].leftImageHeight}px`}})]),_vm._m(0)]),_c('div',{staticClass:"content-rigth"},[_c('img',{attrs:{"src":_vm.aixiliDataContentFankaa[_vm.activeButton].rightImageSrc,"width":`${_vm.aixiliDataContentFankaa[_vm.activeButton].rightImageWidth}px`,"height":`${_vm.aixiliDataContentFankaa[_vm.activeButton].rightImageHeight}px`}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-bottom"},[_c('img',{staticClass:"bottom-image",attrs:{"src":"https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan1%2F1694411664743.jpg"}})])
}]

export { render, staticRenderFns }