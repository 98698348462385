var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qk-aixili-fanka"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"header-left",attrs:{"src":_vm.aixiliTitleImgSrc,"width":`${_vm.aixiliTitleImgWidth}px`,"height":`${_vm.aixiliTitleImgHeight}px`}}),_c('img',{staticClass:"header-right",attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"}})]),_c('div',{staticClass:"content",style:({
      width: `${_vm.aixiliContentWidth}px`,
      height: `${_vm.aixiliContentHeight}px`,
      backgroundSize: `${_vm.aixiliContentBackgroundSize}`,
      backgroundRepeat: `${_vm.aixiliContentBackgroundRepeat}`,
      backgroundImage: `url('${_vm.aixiliContentBackgroundImageSrc}')`,
    })},[_c('div',{staticClass:"content-main"},[_c('div',{staticClass:"main-title"},[_c('img',{attrs:{"src":_vm.aixiliDataContentFankaaaaaa.mainTitleImgSrc,"width":`${_vm.aixiliDataContentFankaaaaaa.mainTitleWidth}px`,"height":`${_vm.aixiliDataContentFankaaaaaa.mainTitleHeight}px`}})]),_c('div',{staticClass:"main-play"},[_c('div',{style:({
            width: `${_vm.aixiliDataContentFankaaaaaa.audioIconWidth}px`,
            height: `${_vm.aixiliDataContentFankaaaaaa.audioIconHeight}px`,
          })},[_c('music',{attrs:{"musicOptions":_vm.musicOptions}})],1)])]),_c('div',{staticClass:"content-mark"},[_c('img',{attrs:{"src":_vm.aixiliDataContentFankaaaaaa.markImgSrc,"width":_vm.aixiliDataContentFankaaaaaa.markWidth,"height":_vm.aixiliDataContentFankaaaaaa.markHeight}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }