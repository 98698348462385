<template>
  <div class="qk-aixili-fanka">
    <div class="header">
      <img
        :src="aixiliTitleImgSrc"
        :width="`${aixiliTitleImgWidth}px`"
        :height="`${aixiliTitleImgHeight}px`"
        class="header-left"
      />
      <img
        src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"
        class="header-right"
      />
    </div>
    <div
      class="content"
      :style="{
        width: `${aixiliContentWidth}px`,
        height: `${aixiliContentHeight}px`,
        backgroundSize: `${aixiliContentBackgroundSize}`,
        backgroundRepeat: `${aixiliContentBackgroundRepeat}`,
        backgroundImage: `url('${aixiliContentBackgroundImageSrc}')`,
      }"
    >
      <div class="content-main">
        <div class="main-title">
          <img
            :src="aixiliDataContentFankaaaaaa.mainTitleImgSrc"
            :width="`${aixiliDataContentFankaaaaaa.mainTitleWidth}px`"
            :height="`${aixiliDataContentFankaaaaaa.mainTitleHeight}px`"
          />
        </div>
        <div class="main-play">
          <div
            :style="{
              width: `${aixiliDataContentFankaaaaaa.audioIconWidth}px`,
              height: `${aixiliDataContentFankaaaaaa.audioIconHeight}px`,
            }"
          >
            <music :musicOptions="musicOptions" />
          </div>
        </div>
      </div>
      <div class="content-mark">
        <img
          :src="aixiliDataContentFankaaaaaa.markImgSrc"
          :width="aixiliDataContentFankaaaaaa.markWidth"
          :height="aixiliDataContentFankaaaaaa.markHeight"
        />
      </div>
    </div>
  </div>
</template>
<script>
import music from "../../../music/index";

export default {
  name: "QkAixiliFanka6",
  components: {
    music,
  },
  props: {
    aixiliTitleImgSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694496856555.jpg",
    },
    aixiliTitleImgWidth: {
      type: Number,
      default: 335,
    },
    aixiliTitleImgHeight: {
      type: Number,
      default: 67,
    },
    aixiliContentBackgroundImageSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694495443912.jpg",
    },
    aixiliContentWidth: {
      type: Number,
      default: 1400,
    },
    aixiliContentHeight: {
      type: Number,
      default: 636,
    },
    aixiliContentBackgroundSize: {
      type: String,
      default: "cover",
    },
    aixiliContentBackgroundRepeat: {
      type: String,
      default: "no-repeat",
    },
    aixiliDataContentFankaaaaaa: {
      type: Object,
      default: () => {
        return {
          mainTitleImgSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694495477360.jpg",
          mainTitleWidth: 612,
          mainTitleHeight: 134,
          markImgSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694495519944.jpg",
          markWidth: 335,
          markHeight: 42,
          audioPlayIcon:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694495501353.jpg",
          audioSrc:
            "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
          audioIconWidth: 108,
          audioIconHeight: 108,
        };
      },
    },
  },
  data() {
    return {
      musicOptions: {},
    };
  },
  created() {
    this.musicOptions = {
      audioSrc: this.aixiliDataContentFankaaaaaa.audioSrc,
      loop: false,
      autoplay: false,
      imageSrc: this.aixiliDataContentFankaaaaaa.audioPlayIcon,
    };
  },
  watch: {
    aixiliDataContentFankaaaaaa: {
      handler(val) {
        this.musicOptions = {
          audioSrc: val.audioSrc,
          loop: false,
          autoplay: false,
          imageSrc: val.audioPlayIcon,
        };
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.qk-aixili-fanka {
  width: 100%;
  height: 100%;
  background: #ffffff;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .header-left {
      margin-left: 84px;
      margin-top: 58px;
    }
    .header-right {
      width: 272px;
      height: 105px;
      margin-top: 34px;
      margin-right: 120px;
    }
  }
  .content {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .content-main {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 140px;
      margin-left: 116px;
      .main-play {
        padding-left: 34px;
        .play-img {
          width: 118px;
          height: 118px;
        }
      }
    }
    .content-mark {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 38px;
      margin-top: -88px;
    }
  }
}
</style>
