import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    meta: {
      title: "登录/注册",
    },
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/",
    meta: {
      title: "首页",
    },
    component: () => import("../views/home/home.vue"),
  },
];

const router = new VueRouter({
  base: process.env.VUE_APP_BASE_ENV === "development" ? "" : "course",
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
