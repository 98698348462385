<template>
  <div class="qk-image-carsousel">
    <Swiper
      v-if="imageSrcList.length > 0"
      class="image-carsousel-swiper"
      :auto-play="true"
      :show-indicator="true"
      :interval="interval"
      duration="500"
    >
      <Slide
        v-for="(item, index) in imageSrcList"
        :key="index"
        class="image-carsousel-slide"
      >
        <img class="image-carsousel-image" :src="item" alt="" />
      </Slide>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, Slide } from "vue-swiper-component";
export default {
  name: "QkImageCarousel",
  components: {
    Swiper,
    Slide,
  },
  props: {
    imageSrcList: {
      type: Array,
      default: () => {
        return [
          "https://static-oss.abiefamily.com/webactive/image/1-5.png",
          "https://static-oss.abiefamily.com/webactive/image/2-2.png",
          "https://static-oss.abiefamily.com/webactive/image/8d0978ca1af04b8f04a02c1960dea8ce.jpg",
        ];
      },
    },
    interval: {
      type: Number,
      default: 2500,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.image-carsousel-swiper,
.image-carsousel-slide,
.image-carsousel-image {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
<style>
.image-carsousel-swiper .wh_swiper {
  height: 100%;
}
</style>
