<template>
  <div class="qk-qrcode">
    <QrcodeVue :value="qrcodeSrc" :size="qrcodeSize" level="H"></QrcodeVue>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  name: "QkQrcode",
  props: {
    alt: {
      type: String,
      default: "qk-qrcode",
    },
    qrcodeSrc: {
      type: String,
      default: "https://www.fandeyinyue.com",
    },
    qrcodeSize: {
      type: Number,
      default: 120,
    },
  },
  components: {
    QrcodeVue,
  },
};
</script>

<style lang="scss" scoped>
.qk-qrcode {
  width: 100%;
  height: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
