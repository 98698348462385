<!-- 图标组件 -->
<template>
  <div class="qk-icon">
    <i :class="iconType"></i>
    <div v-if="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "QkIcon", // 这个名字很重要，它就是未来的标签名 <qk-icon></qk-icon>
  props: {
    iconType: {
      type: String,
      default: "el-icon-edit-outline",
    },
    text: {
      type: String,
      default: "图标",
    },
  },
  data() {
    return {};
  },
  methods: {
    clickEvent() {
      // if
    },
  },
};
</script>
