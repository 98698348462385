var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LuckyWheel',{ref:"LuckyWheel",staticStyle:{"width":"310px","height":"310px"},attrs:{"default-style":{
    fontColor: '#d64737',
    fontSize: '14px',
  },"blocks":[{ padding: '13px', background: '#d64737' }],"prizes":_vm.prizes,"buttons":[
    { radius: '50px', background: '#d64737' },
    { radius: '45px', background: '#fff' },
    { radius: '41px', background: '#f6c66f', pointer: true },
    {
      radius: '35px',
      background: '#ffdea0',
      imgs: [
        {
          src: 'https://static-oss.abiefamily.com/find-webactive/component/lucky-wheel/button.png',
          width: '65%',
          top: '-13%',
        },
      ],
    },
  ]},on:{"start":_vm.startCallBack,"end":_vm.endCallBack}})
}
var staticRenderFns = []

export { render, staticRenderFns }