export const fontFamilyData = [
  {
    name: "morenziti",
    ttf: "",
    url: "",
    thumb: "",
  },
  {
    name: "aZhuPaoPao",
    ttf: "https://cdn.h5ds.com/static/fonts_build/aZhuPaoPao/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/aZhuPaoPao/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/aZhuPaoPao/img.png",
  },
  {
    name: "baoTuXiaoBai",
    ttf: "https://cdn.h5ds.com/static/fonts_build/baoTuXiaoBai/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/baoTuXiaoBai/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/baoTuXiaoBai/img.png",
  },
  {
    name: "cangErFeiBaiW01",
    ttf: "https://cdn.h5ds.com/static/fonts_build/cangErFeiBaiW01/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/cangErFeiBaiW01/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/cangErFeiBaiW01/img.png",
  },
  {
    name: "cangErFeiBaiW05",
    ttf: "https://cdn.h5ds.com/static/fonts_build/cangErFeiBaiW05/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/cangErFeiBaiW05/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/cangErFeiBaiW05/img.png",
  },
  {
    name: "cangErSuYuanTiW01",
    ttf: "https://cdn.h5ds.com/static/fonts_build/cangErSuYuanTiW01/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/cangErSuYuanTiW01/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/cangErSuYuanTiW01/img.png",
  },
  {
    name: "cangErSuYuanTiW05",
    ttf: "https://cdn.h5ds.com/static/fonts_build/cangErSuYuanTiW05/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/cangErSuYuanTiW05/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/cangErSuYuanTiW05/img.png",
  },
  {
    name: "cangErXiaoWanZi",
    ttf: "https://cdn.h5ds.com/static/fonts_build/cangErXiaoWanZi/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/cangErXiaoWanZi/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/cangErXiaoWanZi/img.png",
  },
  {
    name: "cangErYuMoW01",
    ttf: "https://cdn.h5ds.com/static/fonts_build/cangErYuMoW01/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/cangErYuMoW01/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/cangErYuMoW01/img.png",
  },
  {
    name: "cangErYuMoW05",
    ttf: "https://cdn.h5ds.com/static/fonts_build/cangErYuMoW05/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/cangErYuMoW05/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/cangErYuMoW05/img.png",
  },
  {
    name: "cangErYuYangW01",
    ttf: "https://cdn.h5ds.com/static/fonts_build/cangErYuYangW01/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/cangErYuYangW01/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/cangErYuYangW01/img.png",
  },
  {
    name: "cangErYuYangW05",
    ttf: "https://cdn.h5ds.com/static/fonts_build/cangErYuYangW05/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/cangErYuYangW05/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/cangErYuYangW05/img.png",
  },
  {
    name: "cangErZhouKeZhengDaBangShu",
    ttf: "https://cdn.h5ds.com/static/fonts_build/cangErZhouKeZhengDaBangShu/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/cangErZhouKeZhengDaBangShu/font.woff",
    thumb:
      "https://cdn.h5ds.com/static/fonts_build/cangErZhouKeZhengDaBangShu/img.png",
  },
  {
    name: "chuangQiNanAn",
    ttf: "https://cdn.h5ds.com/static/fonts_build/chuangQiNanAn/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/chuangQiNanAn/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/chuangQiNanAn/img.png",
  },
  {
    name: "chuanQiTeZhan",
    ttf: "https://cdn.h5ds.com/static/fonts_build/chuanQiTeZhan/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/chuanQiTeZhan/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/chuanQiTeZhan/img.png",
  },
  {
    name: "douYu-otf",
    url: "https://cdn.h5ds.com/static/fonts_build/douYu-otf/font.otf",
    thumb: "https://cdn.h5ds.com/static/fonts_build/douYu-otf/img.png",
  },
  {
    name: "gongFan",
    ttf: "https://cdn.h5ds.com/static/fonts_build/gongFan/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/gongFan/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/gongFan/img.png",
  },
  {
    name: "naiHu",
    ttf: "https://cdn.h5ds.com/static/fonts_build/naiHu/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/naiHu/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/naiHu/img.png",
  },
  {
    name: "pangMenBiaoTi",
    ttf: "https://cdn.h5ds.com/static/fonts_build/pangMenBiaoTi/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/pangMenBiaoTi/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/pangMenBiaoTi/img.png",
  },
  {
    name: "ruiZiZhenYan",
    ttf: "https://cdn.h5ds.com/static/fonts_build/ruiZiZhenYan/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/ruiZiZhenYan/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/ruiZiZhenYan/img.png",
  },
  {
    name: "shiJieFaGun",
    ttf: "https://cdn.h5ds.com/static/fonts_build/shiJieFaGun/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/shiJieFaGun/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/shiJieFaGun/img.png",
  },
  {
    name: "siYuanHeiTi",
    ttf: "https://cdn.h5ds.com/static/fonts_build/siYuanHeiTi/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/siYuanHeiTi/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/siYuanHeiTi/img.png",
  },
  {
    name: "siYuanSongTi-otf",
    url: "https://cdn.h5ds.com/static/fonts_build/siYuanSongTi-otf/font.otf",
    thumb: "https://cdn.h5ds.com/static/fonts_build/siYuanSongTi-otf/img.png",
  },
  {
    name: "yanShiFoXi",
    ttf: "https://cdn.h5ds.com/static/fonts_build/yanShiFoXi/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/yanShiFoXi/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/yanShiFoXi/img.png",
  },
  {
    name: "zaiZaiJia",
    ttf: "https://cdn.h5ds.com/static/fonts_build/zaiZaiJia/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/zaiZaiJia/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/zaiZaiJia/img.png",
  },
  {
    name: "zhanKuKuaiLe",
    ttf: "https://cdn.h5ds.com/static/fonts_build/zhanKuKuaiLe/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/zhanKuKuaiLe/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/zhanKuKuaiLe/img.png",
  },
  {
    name: "zhanKuKuHei",
    ttf: "https://cdn.h5ds.com/static/fonts_build/zhanKuKuHei/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/zhanKuKuHei/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/zhanKuKuHei/img.png",
  },
  {
    name: "zhengQingKeHuangYou",
    ttf: "https://cdn.h5ds.com/static/fonts_build/zhengQingKeHuangYou/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/zhengQingKeHuangYou/font.woff",
    thumb:
      "https://cdn.h5ds.com/static/fonts_build/zhengQingKeHuangYou/img.png",
  },
  {
    name: "zhuangJiaMingChao",
    ttf: "https://cdn.h5ds.com/static/fonts_build/zhuangJiaMingChao/font.ttf",
    url: "https://cdn.h5ds.com/static/fonts_build/zhuangJiaMingChao/font.woff",
    thumb: "https://cdn.h5ds.com/static/fonts_build/zhuangJiaMingChao/img.png",
  },
];
