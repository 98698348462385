var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qk-aixili-fanka"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"header-left",attrs:{"src":_vm.aixiliTitleImgSrc,"width":`${_vm.aixiliTitleImgWidth}px`,"height":`${_vm.aixiliTitleImgHeight}px`}}),_c('img',{staticClass:"header-right",attrs:{"src":"https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"}})]),_c('div',{staticClass:"content",style:({
      width: `${_vm.aixiliContentWidth}px`,
      height: `${_vm.aixiliContentHeight}px`,
      backgroundSize: `${_vm.aixiliContentBackgroundSize}`,
      backgroundRepeat: `${_vm.aixiliContentBackgroundRepeat}`,
      backgroundImage: `url('${_vm.aixiliContentBackgroundImageSrc}')`,
      position: 'relative',
    })},[_c('div',{staticClass:"content-header",on:{"drop":_vm.dropHeader,"dragover":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"header-list"},_vm._l((_vm.aixiliDataContentFankaaaaa),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:({
            backgroundImage: `url(${item.inActiveButtonImageSrc})`,
            backgroundSize: '190px  50px',
            backgroundRepeat: 'no-repeat',
          }),attrs:{"id":`listItem${index}`}},[_c('img',{staticStyle:{"width":"190px","height":"50px"},attrs:{"id":`dragImg${index}`,"src":item.activeButtonImageSrc,"draggable":"true"},on:{"dragstart":_vm.dragStart}})])}),0),_c('div',{staticClass:"header-line"})]),_c('div',{staticClass:"content-content",style:({
        backgroundImage: `url(${_vm.aixiliDataContentFankaaaaa[_vm.activeButton].mainCourseImageSrc})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: `${_vm.aixiliDataContentFankaaaaa[_vm.activeButton].mainImageWidth}px ${_vm.aixiliDataContentFankaaaaa[_vm.activeButton].mainImageHeight}px`,
      }),on:{"drop":_vm.dropContent,"dragover":function($event){$event.preventDefault();}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }