<template>
  <div
    class="qk-music"
    :class="{ playing: playing }"
    @click.stop="handleMusicPlay"
  >
    <div v-if="musicOptions.audioSrc" class="audio-play-audio">
      <audio
        id="audio-play-audio"
        ref="audioPlayer"
        :src="musicOptions.audioSrc"
        style="opacity: 0"
        :loop="musicOptions.loop"
      ></audio>
    </div>
    <img class="yinyue-img" :src="musicOptions.imageSrc" alt="音乐图标" />
  </div>
</template>

<script>
export default {
  name: "QkMusic",
  props: {
    musicOptions: {
      type: Object,
      default: () => {
        return {
          audioSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/music/7be3b777f9a7e97e945b1656a1024a9c.mp3",
          loop: false,
          autoplay: false,
          imageSrc: require("./music.png"),
        };
      },
    },
  },
  data() {
    return {
      audioEl: undefined,
      playing: true,
    };
  },
  watch: {
    "musicOptions.autoplay": {
      handler: function (val) {
        if (val) {
          this.playing = true;
          this.play();
        } else {
          this.playing = false;
          this.pause();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.audioEl = document.getElementById("audio-play-audio");
    this.audioEl.load();
    const audio = this.$refs.audioPlayer;
    if (this.musicOptions.autoplay) {
      this.playing = true;
      audio.play().catch((e) => {
        this.playing = false;
        this.playError = true;
        const event = ["click", "WeixinJSBridgeReady"];
        const pageClick = () => {
          if (this.playError) {
            this.playError = false;
            audio.load();
            audio.play().catch(() => {
              this.playError = true;
            });
            event.forEach((item) => {
              document.removeEventListener(item, pageClick);
            });
          }
        };
        event.forEach((item) => {
          document.addEventListener(item, pageClick);
        });
      });
    } else {
      this.playing = false;
    }
  },
  methods: {
    handleMusicPlay() {
      if (!this.musicOptions.audioSrc) return;
      if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    },
    play() {
      this.playing = true;
      this.audioEl.play();
    },
    pause() {
      this.playing = false;
      this.audioEl.pause();
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-play-audio {
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 0;
  height: 0;
  z-index: -1;
  opacity: 1;
  overflow: hidden;
}

.yinyue-img {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.qk-music {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  &.playing {
    animation: spin 3s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
