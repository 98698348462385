<!-- 地图组件 -->
<template>
  <div class="qk-map">
    <div id="baidu-map-container"></div>
  </div>
</template>

<script>
export default {
  name: "QkMap", // 这个名字很重要，它就是未来的标签名<qk-map></qk-map>
  props: {
    baiduMapAk: {
      type: String,
      default: "",
    },
    mapDefaultPoint: {
      type: Object,
      default: () => {
        return {
          // 上海市
          // x: 121.48054,
          // y: 31.23593
        };
      },
    },
  },
  data() {
    return {
      defaultStyle: {
        height: 40,
      },
      map: null,
    };
  },
  watch: {
    baiduMapAk(val) {
      if (val) {
        document.body.removeChild(document.getElementById("baidu_map_js"));
        this.$nextTick(this.loadJS);
      }
    },
    mapDefaultPoint(val) {
      if (val && val.x && val.y) {
        // 坐标位置变更，更新地图
        const point = new window.BMapGL.Point(val.x, val.y); // 创建点坐标
        this.map.centerAndZoom(point, 12); // 初始化地图,设置中心点坐标和地图级别
      }
    },
  },
  created() {
    window.baidu_map_init = this.init;
    if (!window.BMapGL) {
      this.loadJS();
    }
  },
  mounted() {
    // this.init();
  },
  methods: {
    loadJS() {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "baidu_map_js";
      script.src = `//api.map.baidu.com/api?type=webgl&v=1.0&ak=${this.baiduMapAk}&callback=baidu_map_init`;
      document.body.appendChild(script);
    },
    init() {
      this.map = new window.BMapGL.Map("baidu-map-container"); // 创建Map实例
      const point = new window.BMapGL.Point(
        this.mapDefaultPoint.x,
        this.mapDefaultPoint.y
      ); // 创建点坐标
      this.map.centerAndZoom(point, 12); // 初始化地图,设置中心点坐标和地图级别
      this.map.enableScrollWheelZoom(); // 启用滚轮放大缩小
    },
  },
};
</script>

<style lang="scss" scoped>
.qk-map {
  width: 100%;
  height: 100%;
  map {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  #baidu-map-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "微软雅黑";
  }
}
</style>
