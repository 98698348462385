<!-- 通知组件 -->
<template>
  <div v-if="show" class="qk-notice">
    <div :class="['qk-notice-containter', colorType]">
      <div class="qk-notice-header">
        <i class="qk-notice-icon">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 1000 1000"
            fill="currentColor"
            focusable="false"
            aria-hidden="true"
          >
            <path
              d="M810.15 874.348c-8.687 0-17.376-3.026-24.37-9.105a36.447 36.447 0 01-3.47-51.714c74.253-83.94 142.954-179.956 142.954-313.862 0-133.877-68.701-229.949-142.955-313.806a36.474 36.474 0 013.47-51.77c15.39-13.329 38.631-11.833 52.186 3.36 79.804 90.158 161.33 203.661 161.33 362.244 0 158.555-81.609 272.086-161.303 362.161a37.196 37.196 0 01-27.841 12.492zM684.24 749.852c-7.467 0-15.045-2.22-21.568-6.884a36.502 36.502 0 01-8.495-51.214l14.685-19.875c38.306-51.436 71.366-95.904 71.366-172.24 0-79.11-29.313-117.472-66.453-166.049-6.579-8.577-13.102-17.21-19.597-26.093a36.474 36.474 0 018.494-51.214c16.617-11.853 39.683-8.1 51.685 8.411 6.052 8.41 12.297 16.6 18.46 24.65 40.027 52.435 81.387 106.646 81.387 210.323 0 100.43-45.524 161.692-85.773 215.793l-14.101 19.153c-7.218 9.993-18.57 15.239-30.09 15.239zM111.033 317.657c-20.314-.047-36.85 16.327-37.002 36.64v290.685c0 20.235 16.627 36.668 37.002 36.668h111.033c9.798 0 19.236 3.858 26.148 10.743l222.12 184.73c3.943 3.886 7.496 7.106 10.799 9.827V112.385a174.46 174.46 0 00-10.854 9.882L248.214 306.859c-6.94 6.94-16.35 10.77-26.148 10.77H111.033v.028zm386.783 654.122c-24.233 0-48.744-16.683-79.75-42.776L206.743 754.987h-95.71C49.826 754.96 0 705.633 0 644.954V354.298c0-60.652 49.826-110.006 111.033-110.006h95.71L417.955 70.415C449.072 43.933 473.166 28 497.621 28c26.537 0 57.543 17.932 57.543 68.34v806.654c0 50.77-30.895 68.785-57.348 68.785z"
              fill-rule="nonzero"
            ></path>
          </svg>
        </i>
      </div>
      <div class="qk-notice-body">
        <span>{{ text }}</span>
      </div>
      <div class="qk-notice-footer">
        <i v-if="closable" class="qk-notice-icon" @click="close">
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 1000 1000"
            fill="currentColor"
            focusable="false"
            aria-hidden="true"
          >
            <path
              d="M560.149 501.223l255.344 255.344c16.272 16.272 16.272 42.654 0 58.926s-42.654 16.272-58.926 0L501.223 560.149 245.88 815.493c-16.272 16.272-42.654 16.272-58.925 0-16.272-16.272-16.272-42.654 0-58.926l255.344-255.344L186.954 245.88c-16.272-16.272-16.272-42.654 0-58.925 16.271-16.272 42.653-16.272 58.925 0l255.344 255.344 255.344-255.344c16.272-16.272 42.654-16.272 58.926 0 16.272 16.271 16.272 42.653 0 58.925L560.149 501.223z"
              fill-rule="nonzero"
            ></path>
          </svg>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QkNotice", // 这个名字很重要，它就是未来的标签名 <qk-notice></qk-notice>
  props: {
    text: {
      type: String,
      default: "通知栏: 可视化平台上线啦",
    },
    closable: {
      // 是否可关闭
      type: Boolean,
      default: true,
    },
    colorType: {
      // 通知类型
      type: String,
      default: "default", // primary/success/warning/info/danger
    },
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
  // watch: {
  //   colorType(val) {
  //     this.color = val
  //   },
  //   color() {
  //     this.$emit('update:colorType', this.color)
  //   },
  // },
  methods: {
    close() {
      if (!this.closable) {
        return;
      }
      this.show = false;
    },
    // selectType(val) {
    //   this.color = val.value
    // },
  },
};
</script>

<style lang="scss" scoped>
.qk-notice {
  .qk-notice-containter {
    width: 100%;
    height: 100%;
    display: flex;
    &.default,
    &.warning {
      background-color: rgba(236, 146, 49, 0.1);
      color: rgb(236, 146, 49);
      .qk-notice-body,
      .qk-notice-header,
      .qk-notice-footer {
        color: rgb(236, 146, 49);
      }
    }
    &.primary {
      background-color: rgba(0, 188, 113, 0.1);
      color: rgb(0, 188, 113);
      .qk-notice-body,
      .qk-notice-header,
      .qk-notice-footer {
        color: rgb(0, 188, 113);
      }
    }
    &.success {
      background-color: #eaf9f1;
      color: #01bc71;
      .qk-notice-body,
      .qk-notice-header,
      .qk-notice-footer {
        color: #01bc71;
      }
    }
    &.danger {
      background-color: #ff50501a;
      color: rgb(255, 80, 80);
      .qk-notice-body,
      .qk-notice-header,
      .qk-notice-footer {
        color: rgb(255, 80, 80);
      }
    }
  }
  .qk-notice-icon {
    display: inline-block;
    color: inherit;
    font-size: 16px;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .qk-notice-header {
    width: 40px;
    text-align: center;
  }
  .qk-notice-body {
    flex: 1;
    // color: rgb(236, 146, 49);
    overflow: hidden;
    white-space: nowrap;
    // 省略号
    // text-overflow: ellipsis;
    box-sizing: border-box;
    position: relative;
    // border: 1px solid #ccc;
  }
  .qk-notice-body > span {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    // width: 100%;
    // background-color: red;
    /* 依次对应：动画名、时长、匀速、无限循环 */
    animation: move 10s linear infinite;
  }

  @keyframes move {
    0% {
      left: 100%;
    }
    // 10% {
    //   left: 100%;
    // }
    // 50% {
    //   left: 0;
    // }
    // 60% {
    //   left: 0;
    // }
    // 90% {
    //   left: -100%;
    // }
    100% {
      left: -100%;
    }
  }
  .qk-notice-footer {
    width: 40px;
    text-align: center;
  }
}
</style>
