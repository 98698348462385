<template>
  <div class="quark-element-wrapper" @click="handleClick">
    <component
      :is="element.elName"
      class="quark-element ani"
      v-bind="element.propsValue"
    />
  </div>
</template>

<script>
// eslint-disable-next-line camelcase
import { _qk_register_components_object } from "../../plugins/index";
import runAnimations from "../../common/js/runAnimations";
import elementEvents from "../../mixins/elementEvents";

export default {
  name: "ComponentsTemplate",
  components: {
    // eslint-disable-next-line camelcase
    ..._qk_register_components_object,
  },
  mixins: [elementEvents],
  props: {
    loaded: Boolean,
    element: {
      type: Object,
      require: true,
      default: undefined,
    },
    slideToPage: {
      type: Function,
      default: null,
    },
  },
  watch: {
    loaded(val) {
      if (val) {
        this.cssAnimations();
      }
    },
  },
  mounted() {
    if (this.loaded) {
      this.cssAnimations();
    }
  },
  methods: {
    async handleClick() {
      for (let i = 0, len = this.element.events.length; i < len; i++) {
        if (this["_event_" + this.element.events[i].type]) {
          if (this.element.events[i].type === "find_webactive_page") {
            const skipPage = this.element.events[i].skipPage;
            if (skipPage.length > 0) {
              this.slideToPage(skipPage[0]);
            }
          } else {
            await this["_event_" + this.element.events[i].type](
              this.element.events[i]
            );
          }
        }
      }
    },
    cssAnimations() {
      const cssText = this.$el.style.cssText;
      const animations = this.element.animations || [];
      runAnimations(this.$el, animations, false, () => {
        this.$el.style.cssText = cssText;
      });
    },
  },
};
</script>
