import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import VueTitle from "vue-wechat-title";
// 默认导出插件
import ElementUI from "element-ui";
import Layout from "./components/layout/layout.vue";
import CustomSelect from "./components/select/select.vue";
import { ColorPicker, Slider, Tooltip, Button, Dialog } from "element-ui";

import "./assets/styles/canvas.scss";

// 修改H5页面标题
Vue.use(VueTitle);
Vue.use(ElementUI);
Vue.use(ColorPicker);
Vue.use(Slider);
Vue.use(Tooltip);
Vue.use(Button);
Vue.use(Dialog);

Vue.component("Layout", Layout);
Vue.component("CustomSelect", CustomSelect);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
