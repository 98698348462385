<template>
  <div class="qk-aixili-fanka">
    <div class="header">
      <img
        :src="aixiliTitleImgSrc"
        :width="`${aixiliTitleImgWidth}px`"
        :height="`${aixiliTitleImgHeight}px`"
        class="header-left"
      />
      <img
        src="https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-70.jpg"
        class="header-right"
      />
    </div>
    <div
      class="content"
      :style="{
        width: `${aixiliContentWidth}px`,
        height: `${aixiliContentHeight}px`,
        backgroundSize: `${aixiliContentBackgroundSize}`,
        backgroundRepeat: `${aixiliContentBackgroundRepeat}`,
        backgroundImage: `url('${aixiliContentBackgroundImageSrc}')`,
      }"
    >
      <div class="content-left">
        <img
          src="https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694502812731.jpg"
          class="left-img"
        />
        <div class="left-select">
          <div class="click-collapse" @click.stop="handleCollapseClick">
            <img
              src="https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694502873944.jpg"
              width="230px"
              height="54px"
            />
            <div v-if="!collapse" class="select-list">
              <div
                v-for="(item, index) in aixiliDataContentFankaaaa"
                :key="index"
              >
                <div
                  v-if="index != activeButton"
                  :key="index"
                  class="list-item"
                  @click.stop="handleButtonClick(index)"
                >
                  <img :src="item.activeButtonImageSrc" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-center">
        <div
          class="center-main"
          :style="{
            width: `${aixiliContentMainWidth}px`,
            height: `${aixiliContentMainHeight}px`,
            backgroundSize: `${aixiliContentMainBackgroundSize}`,
            backgroundRepeat: `${aixiliContentMainBackgroundRepeat}`,
            backgroundImage: `url('${aixiliContentMainBackgroundImageSrc}')`,
          }"
        >
          <div
            v-for="(item, index) in aixiliDataContentFankaaaa"
            :key="index"
            class="main-content"
          >
            <div v-if="index == activeButton" class="main-top">
              <div class="top-top">
                <img
                  src="https://static-oss.abiefamily.com/dev/abstatic-webactive/image/4a2fc8457cc70bbf5cb27cbeaac97cc5.jpg"
                  width="56px"
                  height="56px"
                  @click.stop="nextPage"
                />
              </div>
              <div class="top-bottom">
                <img
                  :src="viewContent.imageSrc"
                  :width="`${viewContent.imageWidth}px`"
                  :height="`${viewContent.imageHeight}px`"
                />
              </div>
            </div>
            <div v-if="index == activeButton" class="main-bottom">
              <div
                class="bottom-left"
                @click.stop="() => (showTips = !showTips)"
              >
                <img
                  src="https://static-oss.abiefamily.com/dev/abstatic-webactive/image/013af3e49a35901c7b1971a4bd2b8898.jpg"
                  width="83px"
                  height="100px"
                />
              </div>
              <div v-if="showTips" class="bottom-right">
                <img
                  :src="item.tipsSection.tipsImageSrc"
                  :width="`${item.tipsSection.tipsImageWidth}px`"
                  :height="`${item.tipsSection.tipsImageHeight}px`"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div
          :style="{
            width: `${aixiliDataContentFankaaaa[activeButton].mainCourseAudioIconWidth}px`,
            height: `${aixiliDataContentFankaaaa[activeButton].mainCourseAudioIconHeight}px`,
          }"
        >
          <music :music-options="viewAudio" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import music from "../../../music/index";
export default {
  name: "QkAixiliFanka4",
  components: {
    music,
  },
  props: {
    aixiliTitleImgSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694426989838.jpg",
    },
    aixiliTitleImgWidth: {
      type: Number,
      default: 400,
    },
    aixiliTitleImgHeight: {
      type: Number,
      default: 80,
    },
    aixiliContentBackgroundImageSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/webactive/psd-batch/1694071001231/psd-resource-68.jpg",
    },
    aixiliContentWidth: {
      type: Number,
      default: 1400,
    },
    aixiliContentHeight: {
      type: Number,
      default: 636,
    },
    aixiliContentBackgroundSize: {
      type: String,
      default: "cover",
    },
    aixiliContentBackgroundRepeat: {
      type: String,
      default: "no-repeat",
    },
    aixiliContentMainBackgroundImageSrc: {
      type: String,
      default:
        "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan2%2F1694505417321.jpg",
    },
    aixiliContentMainWidth: {
      type: Number,
      default: 860,
    },
    aixiliContentMainHeight: {
      type: Number,
      default: 568,
    },
    aixiliContentMainBackgroundSize: {
      type: String,
      default: "cover",
    },
    aixiliContentMainBackgroundRepeat: {
      type: String,
      default: "no-repeat",
    },
    aixiliDataContentFankaaaa: {
      type: Array,
      default: () => [
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694765085278.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694765085278.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/8642cab41979977e23376e4777477ea6.jpg",
          mainCourseImageWidth: 720,
          mainCourseImageHeight: 192,
          mainCourseAudioSrc:
            "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
          mainCourseAudioIconSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
          mainCourseAudioIconWidth: 108,
          mainCourseAudioIconHeight: 108,
          tipsSection: {
            tipsImageSrc:
              "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/d1261d20d1f0aa0d66626c2bf8bf03ee.jpg",
            tipsImageWidth: 700,
            tipsImageHeight: 125,
          },
          descriptionSection: [
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
          ],
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694764575966.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694764575966.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/8642cab41979977e23376e4777477ea6.jpg",
          mainCourseImageWidth: 720,
          mainCourseImageHeight: 192,
          mainCourseAudioSrc:
            "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
          mainCourseAudioIconSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
          mainCourseAudioIconWidth: 108,
          mainCourseAudioIconHeight: 108,
          tipsSection: {
            tipsImageSrc:
              "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/d1261d20d1f0aa0d66626c2bf8bf03ee.jpg",
            tipsImageWidth: 700,
            tipsImageHeight: 125,
          },
          descriptionSection: [
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
          ],
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694764602320.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694764602320.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/8642cab41979977e23376e4777477ea6.jpg",
          mainCourseImageWidth: 720,
          mainCourseImageHeight: 192,
          mainCourseAudioSrc:
            "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
          mainCourseAudioIconSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
          mainCourseAudioIconWidth: 108,
          mainCourseAudioIconHeight: 108,
          tipsSection: {
            tipsImageSrc:
              "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/d1261d20d1f0aa0d66626c2bf8bf03ee.jpg",
            tipsImageWidth: 700,
            tipsImageHeight: 125,
          },
          descriptionSection: [
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
          ],
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694764602320.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694764602320.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/8642cab41979977e23376e4777477ea6.jpg",
          mainCourseImageWidth: 720,
          mainCourseImageHeight: 192,
          mainCourseAudioSrc:
            "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
          mainCourseAudioIconSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
          mainCourseAudioIconWidth: 108,
          mainCourseAudioIconHeight: 108,
          tipsSection: {
            tipsImageSrc:
              "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/d1261d20d1f0aa0d66626c2bf8bf03ee.jpg",
            tipsImageWidth: 700,
            tipsImageHeight: 125,
          },
          descriptionSection: [
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
          ],
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694764651476.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694764651476.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/8642cab41979977e23376e4777477ea6.jpg",
          mainCourseImageWidth: 720,
          mainCourseImageHeight: 192,
          mainCourseAudioSrc:
            "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
          mainCourseAudioIconSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
          mainCourseAudioIconWidth: 108,
          mainCourseAudioIconHeight: 108,
          tipsSection: {
            tipsImageSrc:
              "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/d1261d20d1f0aa0d66626c2bf8bf03ee.jpg",
            tipsImageWidth: 700,
            tipsImageHeight: 125,
          },
          descriptionSection: [
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
          ],
        },
        {
          activeButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694764673537.jpg",
          inActiveButtonImageSrc:
            "https://static-oss.abiefamily.com/find-webactive%2Fcomponent%2Faixili%2Ffanzhuan4%2F1694764673537.jpg",
          mainCourseImageSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/8642cab41979977e23376e4777477ea6.jpg",
          mainCourseImageWidth: 720,
          mainCourseImageHeight: 192,
          mainCourseAudioSrc:
            "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
          mainCourseAudioIconSrc:
            "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
          mainCourseAudioIconWidth: 108,
          mainCourseAudioIconHeight: 108,
          tipsSection: {
            tipsImageSrc:
              "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/d1261d20d1f0aa0d66626c2bf8bf03ee.jpg",
            tipsImageWidth: 700,
            tipsImageHeight: 125,
          },
          descriptionSection: [
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
            {
              descriptionImageSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/cc390190626888c59cf720a8f32d2746.jpg",
              descriptionImageWidth: 720,
              descriptionImageHeight: 192,
              descriptionAudioSrc:
                "https://abstatic.oss-cn-shanghai.aliyuncs.com/webactive/audio/media1.mp3",
              descriptionAudioIconSrc:
                "https://static-oss.abiefamily.com/dev/abstatic-webactive/image/80e3bb48a2b186d962d96bd9588d8c23.png",
              descriptionAudioIconWidth: 108,
              descriptionAudioIconHeight: 108,
            },
          ],
        },
      ],
    },
  },
  data() {
    return {
      collapse: true,
      activeButton: 0,
      showTips: false,
      viewContent: {},
      viewAudio: {},
      currentPage: 0,
    };
  },
  created() {
    this.viewContent = {
      imageSrc:
        this.aixiliDataContentFankaaaa[this.activeButton].mainCourseImageSrc,
      imageWidth:
        this.aixiliDataContentFankaaaa[this.activeButton].mainCourseImageWidth,
      imageHeight:
        this.aixiliDataContentFankaaaa[this.activeButton].mainCourseImageHeight,
    };
    this.viewAudio = {
      audioSrc:
        this.aixiliDataContentFankaaaa[this.activeButton].mainCourseAudioSrc,
      loop: false,
      autoplay: false,
      imageSrc:
        this.aixiliDataContentFankaaaa[this.activeButton]
          .mainCourseAudioIconSrc,
      AudioIconWidth:
        this.aixiliDataContentFankaaaa[this.activeButton]
          .mainCourseAudioIconWidth,
      AudioIconHeight:
        this.aixiliDataContentFankaaaa[this.activeButton]
          .mainCourseAudioIconHeight,
    };
  },
  methods: {
    setActivebutton(index) {
      this.activeButton = index;
      this.viewAudio = {
        audioSrc: this.aixiliDataContentFankaaaa[index].mainCourseAudioSrc,
        loop: false,
        autoplay: false,
        imageSrc: this.aixiliDataContentFankaaaa[index].mainCourseAudioIconSrc,
      };
    },
    handleCollapseClick() {
      this.collapse = !this.collapse;
    },
    handleButtonClick(index) {
      this.activeButton = index;
      this.collapse = !this.collapse;
    },
    nextPage() {
      if (
        this.currentPage ===
        this.aixiliDataContentFankaaaa[this.activeButton].descriptionSection
          .length -
          1
      ) {
        this.viewContent = {
          imageSrc:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .mainCourseImageSrc,
          imageWidth:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .mainCourseImageWidth,
          imageHeight:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .mainCourseImageHeight,
        };
        this.viewAudio = {
          audioSrc:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .mainCourseAudioSrc,
          loop: false,
          autoplay: false,
          imageSrc:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .mainCourseAudioIconSrc,
          AudioIconSrc:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .mainCourseAudioIconSrc,
          AudioIconWidth:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .mainCourseAudioIconWidth,
          AudioIconHeight:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .mainCourseAudioIconHeight,
        };
        this.currentPage = 0;
      } else {
        this.viewContent = {
          imageSrc:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .descriptionSection[this.currentPage].descriptionImageSrc,
          imageWidth:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .descriptionSection[this.currentPage].descriptionImageWidth,
          imageHeight:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .descriptionSection[this.currentPage].descriptionImageHeight,
        };
        this.viewAudio = {
          audioSrc:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .descriptionSection[this.currentPage].descriptionAudioSrc,
          imageSrc:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .descriptionSection[this.currentPage].descriptionAudioIconSrc,
          AudioIconWidth:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .descriptionSection[this.currentPage].descriptionAudioIconWidth,
          AudioIconHeight:
            this.aixiliDataContentFankaaaa[this.activeButton]
              .descriptionSection[this.currentPage].descriptionAudioIconHeight,
          loop: false,
          autoplay: false,
        };
      }
      this.currentPage++;
    },
  },
};
</script>
<style lang="scss" scoped>
.qk-aixili-fanka {
  width: 1290px;
  height: 734px;
  background: #ffffff;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .header-left {
      margin-left: 100px;
      margin-top: 70px;
    }
    .header-right {
      width: 325px;
      height: 125px;
      margin-top: 40px;
      margin-right: 142px;
    }
  }
  .content {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    .content-left {
      width: 363px;
      height: 100%;
      padding-left: 80px;
      padding-right: 55px;
      .left-img {
        width: 42px;
        height: 54px;
        margin-top: 54px;
      }
      .left-select {
        width: 100%;
        margin-top: 20px;
        .click-collapse {
          width: 230px;
          height: 54px;
        }
        .select-list {
          padding: 35px 20px;
          border-radius: 0px 0px 28px 28px;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.6);
          margin-top: -20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .list-item {
            width: 200px;
            height: 54px;
            img {
              max-width: 100%;
            }
          }
        }
      }
    }
    .content-center {
      width: 1030px;
      height: 100%;
      .center-main {
        margin-top: 55px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .main-content {
          width: 100%;
          height: 100%;
          .main-top {
            width: 100%;
            height: 290px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .top-top {
              width: 100%;
              height: 100px;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              margin-top: 50px;
              padding-right: 108px;
            }
            .top-bottom {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              height: 200px;
            }
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .main-bottom {
            width: 100%;
            height: 130px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-left: 30px;
          }
        }
      }
    }
    .content-right {
      width: 290px;
      height: 100%;
    }
  }
}
</style>
